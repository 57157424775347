import { RiMessage2Line } from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { IoMailOutline } from "react-icons/io5";
import ConsultaModalChat from "../ConsultaModalChat/ConsultaModalChat";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import tele from "../../assets/img/telefonista.svg";
import "./SeccionCanales.css";

const SeccionCanales = (props) => {
  const [showModal, setShowModal] = useState(false);

  const info = props.info;
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="fondo-canales">
      <div className="col-12 text-center pt-5 d-none d-sm-none d-md-block">
        <div className="container text-center">
          <h1 className="text-app">NUESTROS CANALES DE COMUNICACIÓN</h1>

          <div className="box-canales">
            <NavLink to={info && "tel:+549" + info.telefono} target="_blank">
              <div className="shadow box-segumas">
                <LiaPhoneVolumeSolid />
              </div>
            </NavLink>
            <NavLink to={info && "mailto:" + info.email} target="_blank">
              <div className="shadow box-segumas">
                <IoMailOutline />
              </div>
            </NavLink>
            <div className="shadow box-segumas">
              <RiMessage2Line onClick={handleShowModal} />
            </div>
            <NavLink
              to={info && "https://wa.me/+549" + info.telefonoWhatsapp}
              target="_blank"
            >
              <div className="shadow box-segumas">
                <BsIcons.BsWhatsapp />
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-12 text-center pt-5  d-sm-block d-md-none">
        <div className="container text-center">
          <h1 className="text-app">NUESTROS CANALES DE COMUNICACIÓN</h1>

          <div className="sm-canales" >
           
            <NavLink to={info && "tel:+549" + info.telefono} target="_blank">
              <div className="shadow box-segumas">
                <LiaPhoneVolumeSolid />
              </div>
            </NavLink>
       
            <NavLink to={info && "mailto:" + info.email} target="_blank">
              <div className="shadow box-segumas">
                <IoMailOutline />
              </div>
            </NavLink>
            <div className="shadow box-segumas">
              <RiMessage2Line onClick={handleShowModal} />
            </div>
            <NavLink
              to={info && "https://wa.me/+549" + info.telefonoWhatsapp}
              target="_blank"
            >
              <div className="shadow box-segumas">
                <BsIcons.BsWhatsapp />
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <ConsultaModalChat show={showModal} onHide={handleCloseModal} />
    </div>
  );
};

export default SeccionCanales;
