import { useState, useEffect } from "react";
import useAuth from "../../auth/useAuth";
import NavFooter from "../../components/NavFooter";
import "./Dashboard.css";
import axios from "axios";
import { url } from "../../service/EndPoint";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import GraficoConsultas from "../../components/GraficoConsultas/GraficoConsultas";
import GraficoConsultasHogar from "../../components/GraficoConsultas/GraficoConsultasHogar";

const Dashboard = ({}) => {
  const auth = useAuth();
  const [consultas, setConsultas] = useState([]);
  const [auto, setAuto] = useState([]);
  const [hogar, setHogar] = useState([]);
  const [chat, setChat] = useState([]);
  function getData() {
    axios
      .post(url + `cotizaciones/reportes/reportes.php`)
      .then((res) => {
        let { data } = res;

        if (data.success && data.message) {
          if (data.success === 1) {
            setConsultas(data.consultas);
            setAuto(data.auto);
            setHogar(data.hogar);
            setChat(data.chat);
          }
        }
      })
      .catch(function (res) {});
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="dashboard">
      <NavFooter auth={auth} />
      <div className="container pt-5">
        <div className="row mb-5">
          <div className="col-md-6 col-sm-12 mb-3 mx-auto ">
            <div className="card">
              <div className="card-body">
                <GraficoConsultas auto={auto && auto} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-3 mx-auto">
            <div className="card">
              <div className="card-body">
                <GraficoConsultasHogar hogar={hogar && hogar} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="consultas/lista">
              <div className="card">
                <div className="card-body position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill "
                    style={{
                      backgroundColor:
                        consultas.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {consultas && consultas.length}
                  </span>
                  Consultas
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="/lista-chat">
              <div className="card">
                <div className="card-body position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                    style={{
                      backgroundColor:
                        chat.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {chat && chat.length}
                  </span>
                  Chat
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="/lista-cotizaciones/auto">
              <div className="card">
                <div className="card-body position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                    style={{
                      backgroundColor:
                        auto.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {auto && auto.length}
                  </span>
                  Cotizaciones Autos
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="/lista-cotizaciones/hogar">
              <div className="card">
                <div className="card-body position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                    style={{
                      backgroundColor:
                        hogar.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {hogar && hogar.length}
                  </span>
                  Cotizaciones Hogar
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="/parametros">
              <div className="card">
                <div className="card-body position-relative">
                  Parametros sitio
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <NavLink exact to="/media">
              <div className="card">
                <div className="card-body position-relative">Media</div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
