"use client";
import "./SeccionAtencion.css";
import * as BsIcons from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa6";
import React from "react";
import { NavLink } from "react-router-dom";

const SeccionAtencion = ({ info }) => {
 
  return (
    <div className="seccionatencion">
      <div
        className=""
        style={{
          background: "#007dbb",
          color: "#fff",
        }}
      >
        <div className="container">
          <div className="link-atencion d-sm-block d-md-none text-white">
          <div className=" d-sm-block d-md-none "><NavLink className="text-white"  exact to='/'>SEGUROS</NavLink></div>           
           <div className=" d-sm-block d-md-none "><NavLink  className="text-white"  exact to='/quienes-somos'>INSTITUCIONAL</NavLink></div>
           <div className=" d-sm-block d-md-none "><NavLink  className="text-white"  exact to='/contactenos'>CONTACTO</NavLink></div>
          </div>
         
          <div className="row pt-3">
            <div className="col-md-2 d-none d-sm-none d-md-block mb-3 pt-2"><NavLink  className="text-white"  exact to='/'>SEGUROS</NavLink></div>           
            <div className="col-md-2 d-none d-sm-none d-md-block mb-3 pt-2"><NavLink  className="text-white"   exact to='/quienes-somos'>INSTITUCIONAL</NavLink></div>
            <div className="col-md-2 d-none d-sm-none d-md-block mb-3 pt-2"><NavLink   className="text-white"  exact to='/contactenos'>CONTACTO</NavLink></div>
            <div
              className="col-md-4 col-sm-12 mb-3 pt-2"
              
            >
              <div className="container">
                <div
                style={{
                  padding: "5px 10px 5px 10px",
                  border: "solid 1px #fff",
                  borderRadius: 30,
                }}
                >
                ATENCIÓN AL CLIENTE 0-800-7777-278
                </div>
              </div>
              
            </div>
            <div
              className="col-md-2 col-sm-12 mb-3 "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <a href={`https://web.whatsapp.com/send/?phone=%2B549${info && info.telefonoWhatsapp}&text=Buen+día+quiero+información%20&app_absent=0`} target="_blank">
                <div className="icono-segumas-f">
                  <BsIcons.BsWhatsapp />
                </div>
              </a>
              <a href="https://www.instagram.com/segumass/" target="_blank">
                <div className="icono-segumas-f">
                  <BsIcons.BsInstagram />
                </div>
              </a>

              <a href="https://www.facebook.com/profile.php?id=61553769747635" target="_blank">
                <div className="icono-segumas-f">
                  <FaFacebookF />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SeccionAtencion.propTypes = {};

export default SeccionAtencion;
