"use client";
import React from "react";
import "./SeccionSlider.css";
import PropTypes from "prop-types";
import { url } from "../../service/EndPoint";

const SeccionSlider = ({info}) => {
	
  return (
    <div className="seccionslider pt-3 " >
      <div
        id="carouselExampleIndicators"
        className="carousel slide pt-5"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img  src={
                  url + "media/imagenes/" + info.background_home
                } className="d-block w-100" alt="..." />
          </div>
		  <div className="carousel-caption d-none d-md-block">
			<h1>Todo lo que necesitás,<br/>lo tenés acá.</h1>
			
		  </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

SeccionSlider.propTypes = {};

export default SeccionSlider;
