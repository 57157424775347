import "./Parametros.css";
import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../service/EndPoint";
import { Form, FloatingLabel, Button, Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Parametros = () => {
  const [formData, setFormData] = useState({
    direccion: "",
    telefono: "",
    telefonoWhatsapp: "",
    quienes: "",
    cuit: "",
    razonSocial: "",
    condicion: "",
    horario: "",
    email: "",
    background_home: "",
    background_home_mobile: "",
    facebook_url: "",
    instagram_url: "",
    background_hogar: "",
    background_hogar_mobile: "",
    promocion_hogar: "",
    background_auto: "",
    background_auto_mobile: "",
    promocion_auto: "",
    latitud:"",
    longitud:"",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .post(`${url}parametros/get/parametros.php`)
      .then((response) => {
        setFormData(response.data[0]);
      })
      .catch((error) => {
        setError("Error al obtener datos: " + error.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${url}parametros/update/parametros.php`, formData)
      .then((res) => {
        const { data } = res;
        if (Number(data.success) === 1) {
          setSuccess(true);
          setError(null);
        }
      })
      .catch((error) => {
        setError("Error al actualizar datos: " + error.message);
        setSuccess(false);
      });
  };

  const handleEditorChange = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      quienes: content,
    }));
  };

  return (
    <div className="parametros">
      <div className="container">
        <div className="text-start">
          <NavLink exact to={"/dashboard"}>
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort className="app-color" />
            </h1>
          </NavLink>
        </div>
        <div
          className="card shadow mb-3"
          style={{ background: "#292740", border: "0px" }}
        >
          <div className="card-body">
            <div className="text-center text-white">Parametros</div>
          </div>
        </div>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Actualizado exitosamente</Alert>}

        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="direccion" label="Dirección">
                <Form.Control
                  type="text"
                  name="direccion"
                  value={formData.direccion}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="horario" label="Horario">
                <Form.Control
                  type="text"
                  name="horario"
                  value={formData.horario}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="telefono" label="Teléfono">
                <Form.Control
                  type="tel"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <FloatingLabel controlId="email" label="Email">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-6 col-sm-12">
              <FloatingLabel
                controlId="telefonoWhatsapp"
                label="Teléfono Whatsapp"
              >
                <Form.Control
                  type="tel"
                  name="telefonoWhatsapp"
                  value={formData.telefonoWhatsapp}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <FloatingLabel controlId="latitud" label="Latitud">
                <Form.Control
                  type="text"
                  name="latitud"
                  value={formData.latitud}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-6 col-sm-12">
              <FloatingLabel
                controlId="longitud"
                label="Longitud"
              >
                <Form.Control
                  type="text"
                  name="longitud"
                  value={formData.longitud}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
          </div>

          <div className="mb-3 text-start">
            <small className="text-muted m-3">Quienes somos</small>
            <ReactQuill
              value={formData.quienes}
              onChange={handleEditorChange}
            />
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="cuit" label="CUIT">
                <Form.Control
                  type="text"
                  name="cuit"
                  value={formData.cuit}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="razonSocial" label="Razón Social">
                <Form.Control
                  type="text"
                  name="razonSocial"
                  value={formData.razonSocial}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-4 col-sm-12">
              <FloatingLabel controlId="condicion" label="Condición">
                <Form.Control
                  type="text"
                  name="condicion"
                  value={formData.condicion}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <FloatingLabel controlId="facebook" label="Facebook">
                <Form.Control
                  type="text"
                  name="facebook_url"
                  value={formData.facebook_url}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
            <div className="col-md-6 col-sm-12">
              <FloatingLabel controlId="instagram" label="Instagram">
                <Form.Control
                  type="text"
                  name="instagram_url"
                  value={formData.instagram_url}
                  onChange={handleChange}
                  className="mb-3"
                />
              </FloatingLabel>
            </div>
          </div>

          <div className="col-6 mx-auto">
            <div className="d-grid gap-2 pt-2">
              <Button type="submit" className="btn app-button text-white shadow">
                Actualizar
              </Button>
            </div>
          </div>
        </Form>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Parametros;
