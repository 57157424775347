import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useState } from "react";
import { url } from "../../service/EndPoint";

function ConsultaModalAuto({ show, onHide, data, actualizar }) {
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }
  useEffect(() => {
    setSolicitud(data);
  }, [data]);

  const [solicitud, setSolicitud] = useState("");

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });
  };
  const [activar, setActivar] = useState(true);

  function enviar() {
    axios
      .post(url + `cotizaciones/auto/update.php`, solicitud)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            actualizar();
            onHide();
          }
        }
      })
      .catch(function (res) {});
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>Fecha solicitud {data && formatearFecha(data.create_at)}</div>
        </div>
        <form>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="nombre"
                    placeholder="NOMBRE"
                    onChange={onChangeForm}
                    value={solicitud && solicitud.nombre}
                  />
                  <label htmlFor="Nombre">NOMBRE</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="apellido"
                    value={solicitud && solicitud.apellido}
                    placeholder="APELLIDO"
                    onChange={onChangeForm}
                  />
                  <label htmlFor="Apellido">APELLIDO</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="dni"
                    value={solicitud && solicitud.dni}
                    placeholder="DNI"
                    pattern="[0-9]+"
                    minLength={6}
                    maxLength={10}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="DNI">DNI</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="telefono"
                    value={solicitud && solicitud.telefono}
                    placeholder="01143214321"
                    pattern="[0-9]+"
                    minLength={6}
                    maxLength={15}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="CELULAR">CELULAR</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={solicitud && solicitud.email}
                    placeholder="E.MAIL"
                    onChange={onChangeForm}
                  />
                  <label htmlFor="mail">E.MAIL</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="codigo"
                    name="codigoPostal"
                    value={solicitud && solicitud.codigoPostal}
                    placeholder="1234"
                    minLength={4}
                    maxLength={4}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="codigo">CÓD. POSTAL</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="condicion"
                  name="iva"
                  value={solicitud && solicitud.iva}
                  aria-label="CONDICIÓN IMPOSITIVA"
                  onChange={onChangeForm}
                >
                  <option id="Consumidor Final" value={5}>
                    CONSUMIDOR FINAL
                  </option>
                  <option id="Exento" value={4}>
                    EXENTO
                  </option>
                  <option id="Gran Contribuyente" value={2}>
                    GRAN CONTRIBUYENTE
                  </option>
                  <option id="IVA No Categorizados RG.212" value={8}>
                    IVA NO CATEGORIZADOS RG.212
                  </option>
                  <option id="Monotributo" value={7}>
                    MONOTRIBUTO
                  </option>
                  <option id="Responsable Inscripto" value={1}>
                    RESPONSABLE INSCRIPTO
                  </option>
                  <option id="Tierra del Fuego" value={6}>
                    TIERRA DEL FUEGO
                  </option>
                </select>
                <label htmlFor="condicion">CONDICIÓN IMPOSITIVA</label>
                <div className="invalid-feedback">
                  Por favor completa este campo
                </div>
              </div>
            </div>
          </div>       
        
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="marca"
                    name="marca"
                    type="text"
                    value={solicitud && solicitud.marca}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="marca">MARCA</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="modelo"
                    name="modelo"
                    value={solicitud && solicitud.modelo}
                    onChange={onChangeForm}
                    type="text"
                  />
                  <label htmlFor="modelo">MODELO</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="year"
                    name="anio"
                    value={solicitud && solicitud.anio}
                    onChange={onChangeForm}
                  />  
                  <label htmlFor="ANO">AÑO</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="version"
                  name="version"
                  type="text"
                  value={solicitud && solicitud.version}
                  onChange={onChangeForm}
                />
                <label htmlFor="version">VERSIÓN</label>
                <div className="invalid-feedback">
                  Por favor completa este campo
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
            <div className="col-md">
                <div
                  style={{
                    background: "#fff",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="ceroKm"
                      checked={solicitud && solicitud.ceroKm === 'S' ? true : false}
                      id="flexCheckDefault"
                      onChange={onChangeForm}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      0KM
                    </label>
                  </div>
                </div>
              </div>     
              <div className="col-md">
                <div className="form-floating">
                  <input className="form-control"
                    name="gnc"
                    value={solicitud && solicitud.gnc}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="gnc">GNC</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="uso"
                    name="uso"
                    aria-label="USO"
                    value={solicitud && Number(solicitud.uso) === 1 ? 'PARTICULAR': 'PRIVADO'}
                    onChange={onChangeForm}
                 />
  
                  <label htmlFor="uso">USO</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="patente"
                    name="patente"
                    value={solicitud && solicitud.patente}
                    placeholder="PATENTE"
                    onChange={onChangeForm}
                  />
                  <label htmlFor="patente">PATENTE</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
    
            <div className="row ">
           
                <div className="form-floating">
                  <textarea                   
                    className="form-control"
                    id="observacion"
                    name="observacion"
                    value={solicitud && solicitud.observacion}
                    placeholder="OBSERVACION"
                    onChange={onChangeForm}
                    style={{height: "100px",background:'#f5f5f5 !important'}}
                  >
                    </textarea>
                  <label htmlFor="observacion">OBSERVACION</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
         
            </div>
    
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Estado"
                  name="estado"
                  onChange={onChangeForm}
                  value={solicitud && solicitud.estado}
                >
                  <option selected>
                    {Number(solicitud && solicitud.estado) === 0 && "Nuevo"}
                    {Number(solicitud && solicitud.estado) === 1 &&
                      "Pendiente"}{" "}
                    {Number(solicitud && solicitud.estado) === 2 && "Cerrada"}
                    {Number(solicitud && solicitud.estado) === 3 && "Cotizada"}
                  </option>
                  <option value={0}>Nuevo</option>
                  <option value={1}>Pendiente</option>
                  <option value={2}>Cerrado</option>
                  <option value={3}>Cotizada</option>
                </select>
                <label htmlFor="floatingSelect">Estado</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <button
                className="btn app-button btn-block text-white"
                type="button"
                onClick={() => activar && enviar()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ConsultaModalAuto;
