import "./ListaCotizacionHogar.css";
import React, {  useState, useEffect } from "react";
import { url } from "../../service/EndPoint";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import ConsultaModalHogar from "../../components/ConsultaModalHogar/ConsultaModalHogar";

const ListaCotizacionHogar = ({}) => {
  const [filtro, setFiltro] = useState("");
  const [data, setData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get(url + "cotizaciones/hogar/list.php")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud GET:", error);
      });
  }, []);
  
    function actualizar() {
      axios.get(url + "cotizaciones/hogar/list.php")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud GET:", error);
      });
  }
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  return (
    <div className="listaconsultas">
      <div className="container mx-auto pt-5">
        <div className="row pt-3">
          <div className="col-1">
            <NavLink exact to={"/dashboard"}>
              <h1>
                <BsIcons.BsArrowLeftShort className="app-color display-1" />
              </h1>
            </NavLink>
          </div>
          <div className="col">
            <h1 className="titulo-1 pt-3">Solcicitudes cotización Hogar</h1>
          </div>
        </div>
        <div className="pt-5 mb-3">
          <div className="input-group ">
            <input
              placeholder="Buscar"
              className="form-control form-control-sm"
              type="text"
              onChange={(e) => setFiltro(e.target.value)}
              
            />
            <span className="input-group-text" id="basic-addon2">
              <BsIcons.BsSearch />
            </span>
          </div >
          <hr/><br/>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre</th>
              <th scope="col">Telefono</th>
              <th scope="col">Localidad</th>
              <th scope="col">Fecha</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((d) => {
                  const nombre = d.nombre || "";
                  const apellido = d.apellido || "";
                  const create_at = d.create_at || "";
                  const estado = d.estado || "";
                  return (
                    nombre
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    apellido
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    create_at
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    estado
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase())
                  );
                })
                .map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => handleShowModal(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <th scope="row">{index + 1}</th>
                    <td>{item.nombre}, {item.apellido}</td>
                    <td>{item.telefono}</td>
                    <td>{item.localidad}</td>
                    <td>{formatearFecha(item.create_at)}</td>
                    <td>
                      {Number(item && item.estado) === 0 && (
                        <span className="badge-danger">Nuevo</span>
                      )}
                      {Number(item && item.estado) === 1 && (
                        <span className="badge-warning">Pendiente</span>
                      )}{" "}
                      {Number(item && item.estado) === 2 && (
                        <span className="badge-success">Cerrada</span>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        <ConsultaModalHogar
          show={showModal}
          onHide={handleCloseModal}
          data={selectedItem}
          actualizar={actualizar}
        />
      </div>
    </div>
  );
};

export default ListaCotizacionHogar;
