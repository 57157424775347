import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import React from "react";
import "./EmisionAuto.css";
import { url } from "../../service/EndPoint";
import { NavLink, useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Tarjeta from "../../components/Tarjeta/Tarjeta";
import axios from "axios";
import CoberturaMovil from "../../components/CoberturaMovil/CoberturaMovil";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const EmisionAuto = (props) => {
  const history = useHistory();
  const urls = window.location.href;
  const id = urls.split("/").pop();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const datas = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const [data, setData] = useState(null);
  const [plan, setPlan] = useState(null);
  const [datosCliente,setDatosCliente] = useState(null)
  const [emitiendo,setEmitiendo]= useState(false)
  const [datos, setDatos] = useState(
    null || JSON.parse(localStorage.getItem("cotizacion"))
  );
console.log(datos)
  useEffect(() => {
    if (datas) {
      setData(datas.data);
      setPlan(datas.plan);

    }

  }, []);
  async function fetchCotizacion() {
    try {
      const response = await axios.get(
        url + "cotizaciones/auto/cotizacion.php?id=%27" + data.email+"%27"
      );
  
     
      setDatosCliente(response.data[0]);
   
    } catch (error) {
      console.error("Error al obtener la cotización:", error);
    }
  }
  

 
  useEffect(() => {
    if (data) {
     
      fetchCotizacion()
    }
  }, [data]);

 const valor1 = datosCliente ? datosCliente.telefono.substring(0, 3) : '';
 const valor2 = datosCliente ? datosCliente.telefono.slice(3) : '';
  useEffect(() => {
    if (data) {
     
      setCotizacion((prevCotizacion) => ({
        ...prevCotizacion,
        nombres: data.nombres,
        apellido: data.apellido,
        email: data.email,
        presupuesto: data.nroPresupuesto,
        plan: plan.codigo,
        codigoPostal: data.codigoPostal,
        numeroDocumento: datosCliente ? datosCliente.dni : '',
        prefijo: valor1,
        telefono: valor2,
        patente: datosCliente ? datosCliente.patente : '',
      }));
    }
  }, [datosCliente]);
  
 
  const [currentStep, setCurrentStep] = useState(1);

  const totalSteps = 5;

  const initialCotizacion = {
    actividad: "",
    actividadFinal: "",
    apellido: "",
    calle: "",
    cbu: "",
    chasis: "",
    codigoPostal: "",
    color: "",
    comentario: "",
    cuit: "",
    ddjj: "N",
    departamento: "",
    email: "",
    estadoCivil: "",
    fechaContacto: "",
    fechaInstalacion: "",
    fechaNacimiento: "",
    fechaPP: "",
    fechaVencimiento: "",
    fechaVigencia: "",
    formaCobro: "TM",
    generaCertificado: "",
    generaSolicitud: "",
    gestor: "",
    gestorPP: "",
    horaDesde: "",
    horaHasta: "",
    identificador: "Referencia",
    interviniente: "",
    lugarNacimiento: "",
    motor: "",
    nacionalidad: "",
    nombres: "",
    nroCuenta: "",
    nroTarjeta: "",
    numero: 9000,
    numeroCertificado: "",
    numeroDocumento: "",
    numeroEquipo: "",
    numeroPP: "",
    patente: "",
    pep: "NO",
    piso: "",
    plan: "",
    porcentajeModalidad: 18,
    posicionIva: 5,
    prefijo: "",
    prefijo2: "",
    presupuesto: "",
    productor: 26142,
    provincia: "",
    sexo: "M",
    suscribeDep: "",
    tarjeta: "",
    telefono: "",
    telefono2: "",
    telefonoContacto: "",
    tipoCuenta: "",
    tipoDePersona: "",
    tipoDocumento: "DNI",
    tipoPersona: "FISICA",
    titular: "",
    vencimientoPP: "",
  };
  const formatoPesosArgentinos = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });
  const [cotizacion, setCotizacion] = useState(initialCotizacion);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCotizacion((prevCotizacion) => ({
      ...prevCotizacion,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes realizar acciones adicionales con los datos, como enviarlos a un servidor, etc.

    // Si no estás en el último paso, pasa al siguiente
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      //console.log(token)
    }
  };

  const validarFormatoFecha = (valor) => {
    // Verifica si el valor es una cadena de texto
    if (typeof valor !== "string") {
      return false;
    }

    // Aplica la expresión regular para verificar el formato deseado
    const formatoFechaRegExp = /^\d{2}\/\d{2}$/;

    return formatoFechaRegExp.test(valor);
  };
  const handleChangeFecha = (e) => {
    const nuevoValor = e.target.value;

    // Realiza la validación al cambiar el valor
    if (validarFormatoFecha(nuevoValor)) {
      setCotizacion((prevCotizacion) => ({
        ...prevCotizacion,
        fechaVencimiento: nuevoValor,
      }));
    }
  };
  const handleChangeFechaNacimineto = (e) => {
    const nuevoValor = e.target.value;

    // Realiza la validación al cambiar el valor
   
      setCotizacion((prevCotizacion) => ({
        ...prevCotizacion,
        fechaNacimiento: moment(nuevoValor).format("DD/MM/YYYY"),
      }));
   
  };
  const [token, setToken] = useState("");

  const [tarjetas, setTarjetas] = useState([]);

  async function fetchToken() {
    try {
      const response = await axios.post(
        "https://autos-api.apiexperta.com.ar/login",
        {
          user: "segumas",
          password: "ae9950b87ffc3b372739ad468bb2a676",
        }
      );

      const { jwt } = response.data;
      setToken(jwt);
    } catch (error) {
   //   console.error("Error al obtener el token:", error);
    }
  }

  async function fetchEmision() {
    setEmitiendo(true)
    try {
      const response = await axios.post(
        "https://autos-api.apiexperta.com.ar/emisiones",
        cotizacion,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "api-key": "a56b244a-b869-4a00-a88a-e89ffe6a2bb4",
          },
        }
      );

      const { info } = response.data;
      
      if(info){
        axios
      .post(url + `cotizaciones/auto/emisionRealizada.php`, info)
      .then((res) => {})
      .catch(function (res) {});
      }
     
      axios
      .post(url + `cotizaciones/auto/emisionSolicitud.php`, cotizacion)
      .then((res) => {
       
        if (res) {
       setEmisionEnProceso(true);
        }
      })
      .catch(function (res) {  
        
      });
    } catch (error) {
      window.alert(error.response.data)
      setEmitiendo(false)
   //  console.error("Error al obtener el emision:", error.message);
    }
  }

  useEffect(() => {
    fetchToken();

    const intervalId = setInterval(() => {
      fetchToken();
    }, 900000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  async function fetchTarjetas() {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/tarjetas",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTarjetas(response.data);
    } catch (error) {
   //   console.error("Error al obtener las tarjetas:", error);
    }
  }
const [productor,setProductor] = useState(null)
  async function fetchProductor() {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/forma-pago?productor=26142",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProductor(response.data);
     // console.log(productor)
    } catch (error) {
    //  console.error("Error al obtener las tarjetas:", error);
    }
  }
  const [bancos, setBancos] = useState([]);
  async function fetchBancos() {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/bancos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBancos(response.data);
    } catch (error) {
     // console.error("Error al obtener las bancos:", error);
    }
  }
  useEffect(() => {
    fetchProductor();
    fetchTarjetas();
    fetchBancos();
  }, [token]);

  function extraerTextoDespuesDelParentesis(cadena) {
    
    var patron = /^(.*?)\(/;

   
    var coincidencia = cadena.match(patron);

    // Verificar si hay una coincidencia
    if (coincidencia) {
      // La parte extraída está en la posición 1 del array resultante
      var textoExtraido = coincidencia[1].trim();

      // Devolver el resultado
      return textoExtraido;
    } else {
      // En caso de que no haya coincidencia, devolver null o un valor predeterminado
      return null;
    }
  }
  const [emisionEnProceso, setEmisionEnProceso] = useState(false);
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            {/* Renderiza los campos del paso 1 aquí */}
            <div className="row g-2">
              <h1>Datos personales</h1>
              <div className="col">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="nombres"
                    name="nombres"
                    value={cotizacion && cotizacion.nombres}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="nombres">Nombres</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="apellido"
                    name="apellido"
                    value={cotizacion && cotizacion.apellido}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="apellido">Apellido</label>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-3">
              <div className="col">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="sexo"
                    name="sexo"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </select>
                  <label for="sexo">Sexo</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    max="2005-12-31"
                   
                    onChange={(e) => handleChangeFechaNacimineto(e)}
                    required
                  />
                  <label for="fechaNacimiento">Fecha de Nacimiento</label>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-3">
              <div className="col-md-5 col-sm-12">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={cotizacion && cotizacion.email}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="email">Correo Electrónico</label>
                </div>
              </div>
              <div className="col-md-2 col-sm-4">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="tipoDocumento"
                    name="tipoDocumento"
                    value={cotizacion && cotizacion.tipoDocumento}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <label for="tipoDocumento">Tipo de Documento</label>
                </div>
              </div>
              <div className="col-md-5 col-sm-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="numeroDocumento"
                    name="numeroDocumento"
                    value={cotizacion && cotizacion.numeroDocumento}
                    onChange={(e) => handleChange(e)}
                    maxlength="8"
                    minlength="7"
                    required
                  />
                  <label for="numeroDocumento">Número de Documento</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-3">
              <div className="col-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="prefijo"
                    name="prefijo"
                    value={cotizacion && cotizacion.prefijo}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="prefijo">Prefijo</label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="telefono"
                    name="telefono"
                    value={cotizacion && cotizacion.telefono}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="telefono">Teléfono</label>
                </div>
              </div>
            </div>

            {/* Otros campos del paso 1 */}
          </div>
        );

      case 2:
        return (
          <div className="row">
            {/* Renderiza los campos del paso 2 aquí */}
            <div className="col">
              <div className="row g-2">
                <h1>Formas de pago</h1>
                <div className="col">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="formaCobro"
                      name="formaCobro"
                      value={cotizacion && cotizacion.formaCobro}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value="TM">TARJETA DE CREDITO</option>
                      <option value="BA">CBU</option>
                      <option value="CC">EFECTIVO</option>
                    </select>
                    <label for="formaCobro">Forma de pago</label>
                  </div>
                </div>
              </div>
              {cotizacion && cotizacion.formaCobro === "TM" && (
                <>
                  <div className="row g-2 mt-3">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="tarjeta"
                        name="tarjeta"
                        aria-label="tarjeta"
                        value={cotizacion && cotizacion.tarjeta}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option></option>
                        {tarjetas &&
                          tarjetas.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.descripcion}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="tarjeta">Tarjeta</label>
                      <div className="invalid-feedback">
                        Por favor completa este campo
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="titular"
                          name="titular"
                          minlength="5"
                          value={cotizacion && cotizacion.titular}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <label for="titular">Titular</label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="nroTarjeta"
                          name="nroTarjeta"
                          maxlength="16"
                          minlength="16"
                          value={cotizacion && cotizacion.nroTarjeta}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <label for="nroTarjeta">Número de Tarjeta</label>
                      </div>
                    </div>
                  </div>

                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="fechaVencimiento"
                          name="fechaVencimiento"
                          maxlength="7"
                          minlength="7"
                          pattern="(0[1-9]|1[0-2])/(20[2-9][0-9]|2[1-9][0-9]{2})"
                          autocomplete="off"
                          value={cotizacion && cotizacion.fechaVencimiento}
                          onChange={(e) => handleChangeFecha(e)}
                          required
                        />
                        <label for="fechaVencimiento">
                          Vencimineto Mes/Año ejemplo: 02/2028 
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {cotizacion && cotizacion.formaCobro === "BA" && (
                <>
                  <div className="row g-2 mt-3">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="bancos"
                        name="bancos"
                        aria-label="bancos"
                        value={cotizacion && cotizacion.bancos}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option></option>
                        {bancos &&
                          bancos.map((data, index) => (
                            <option value={data.codigo} key={index}>
                              {extraerTextoDespuesDelParentesis(
                                data.descripcion
                              )}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="bancos">Bancos</label>
                      <div className="invalid-feedback">
                        Por favor completa este campo
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="titular"
                          name="titular"
                          minlength="5"
                          value={cotizacion && cotizacion.titular}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <label for="titular">Titular</label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="cuenta"
                        name="cuenta"
                        aria-label="cuenta"
                        value={cotizacion && cotizacion.cuenta}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option></option>
                        <option value="Ahorros">Caja de ahorros</option>
                        <option value="Sueldo">Cuenta sueldo</option>
                        <option value="Corriente">Cuenta corriente</option>
                      </select>
                      <label htmlFor="cuenta">Tipo de cuenta</label>
                      <div className="invalid-feedback">
                        Por favor completa este campo
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="nroCuenta"
                          name="nroCuenta"
                          value={cotizacion && cotizacion.nroCuenta}
                          onChange={(e) => handleChange(e)}
                          maxlength="22"
                          minlength="6"
                          required
                        />
                        <label for="numeroDocumento">Número de Cuenta</label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="22"
                          minlength="22"
                          id="cbu"
                          name="cbu"
                          value={cotizacion && cotizacion.cbu}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <label for="tipoDocumento">CBU</label>
                      </div>
                    </div>
                  </div>
                </>
              )}
               {cotizacion && cotizacion.formaCobro === "BA" && (
                <div>
                               <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              ></div>
                </div>
               )}
            </div>
            <div className="col-6 pt-5 d-none d-md-block d-sm-none mx-auto">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {cotizacion && cotizacion.formaCobro === "TM" && (
                  <>
                    <Tarjeta
                      titular={cotizacion.titular}
                      nroTarjeta={cotizacion.nroTarjeta}
                      tarjeta={cotizacion.tarjeta}
                      cvv={cotizacion.cvv}
                      venci={cotizacion.fechaVencimiento}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div>
            {/* Renderiza los campos del paso 3 aquí */}

            <h1>Datos del vehículo</h1>
            <div className="row g-2 ">
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="marca"
                    name="marca"
                    value={data && data.marca}
                    disabled
                  />
                  <label for="gestor">Marca</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="version"
                    name="version"
                    value={data && data.version}
                    disabled
                  />
                  <label for="gestor">Version</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-3">
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="chasis"
                    name="chasis"
                    value={cotizacion && cotizacion.chasis}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <label for="chasis">Id de Chasis</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="motor"
                    name="motor"
                    value={cotizacion && cotizacion.motor}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <label for="motor">Id de motor</label>
                </div>
              </div>
              {data && data.gnc !== "N" && (
                <div className="col-md-6 col-sm-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="numeroEquipo"
                      name="numeroEquipo"
                      value={cotizacion && cotizacion.numeroEquipo}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                    <label for="numeroEquipo">Numero equipo GNC</label>
                  </div>
                </div>
              )}
            </div>
            <div className="row g-2 mt-3">
              <div className="col">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="patente"
                    name="patente"
                    maxlength="7"
                    minlength="6"
                    value={cotizacion && cotizacion.patente}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="patente">Patente</label>
                </div>
              </div>
            </div>

            {/* Otros campos del paso 3 */}
          </div>
        );

      case 4:
        return (
          <div>
            {/* Renderiza los campos del paso 3 aquí */}
            <div className="row g-2 ">
              <h1>Datos del domicilio</h1>
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="codigoPostal"
                    name="codigoPostal"
                    value={cotizacion && cotizacion.codigoPostal}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="codigoPostal">Código Postal</label>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="calle"
                    name="calle"
                    value={cotizacion && cotizacion.calle}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <label for="calle">Calle y numero</label>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-3">
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="departamento"
                    name="departamento"
                    value={cotizacion && cotizacion.departamento}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="departamento">Departamento</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="piso"
                    name="piso"
                    value={cotizacion && cotizacion.piso}
                    onChange={(e) => handleChange(e)}
                  />
                  <label for="piso">Piso</label>
                </div>
              </div>
            </div>

            {/* Otros campos del paso 3 */}
          </div>
        );

      case 5:
        return (
          <div>
            {/* Renderiza los campos del paso 4 aquí */}
            <div className="row g-2 mb-5">
              <div className="col-md-6 col-sm-12">
                <div className="card mt-3">
                  <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-muted">
                      Valor Cobertura
                    </h6>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Plan:</strong>
                          </div>
                          <div>{datas && datas.plan.descripcion}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Prima:</strong>
                          </div>
                          <div>{datas && formatoPesosArgentinos.format(datas.plan.prima)}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Franquicia:</strong>
                          </div>
                          <div>{datas && formatoPesosArgentinos.format(datas.plan.franquicia)}</div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Franquicia Robo:</strong>
                          </div>
                          <div>{datas && formatoPesosArgentinos.format(datas.plan.franquiciarobo)}</div>
                          
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Precio:</strong>
                          </div>
                          <div> {cotizacion && cotizacion.formaCobro === "TM" && formatoPesosArgentinos.format(datas.plan.debito)}</div>
                          <div> {cotizacion && cotizacion.formaCobro === "BA" && formatoPesosArgentinos.format(datas.plan.debito)}</div>
                          <div>  {cotizacion && cotizacion.formaCobro === "CC" && formatoPesosArgentinos.format(datas.plan.efectivo)}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-5">
                <div className="card mt-3">
                  <div className="card-body">
                    <CoberturaMovil data={datas.plan.coberturas} />
                  </div>
                </div>
              </div>
            </div>

            {/* Otros campos del paso 4 */}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="emisionauto">
      <div className="container pt-3">
        <div className="text-start">
          <NavLink
            exact
            to={
              !emisionEnProceso
                ? "/planes-cotizacion/" +
                  encodeURIComponent(
                    CryptoJS.AES.encrypt(
                      JSON.stringify(data && { datos: data, plan: plan }),
                      "clave_secreta"
                    ).toString()
                  )
                : "/seguro-auto"
            }
          >
            <h1>
              <BsIcons.BsArrowLeft />
            </h1>
          </NavLink>
        </div>
      </div>
      {!emisionEnProceso ? (
        <div className="container pt-2">
          <div className="row">
            <div className="col-8 mx-auto">
              <ProgressBar
                width={"100%"}
                percent={
                  (currentStep === 1 && 0) ||
                  (currentStep === 2 && 25) ||
                  (currentStep === 3 && 50) ||
                  (currentStep === 4 && 75) ||
                  (currentStep === 5 && 100) ||
                  0
                }
                filledBackground="rgb(33, 52, 80)"
              >
                <Step>
                  {({ accomplished }) => (
                    <div
                      style={{
                        background: "rgb(33, 52, 80)",
                        height: "50px",
                        width: "50px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      1
                    </div>
                  )}
                </Step>
                <Step>
                  {({ accomplished }) => (
                    <div
                      style={{
                        background: accomplished
                          ? "rgb(33, 52, 80)"
                          : "#17a0d5",
                        height: "50px",
                        width: "50px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      2
                    </div>
                  )}
                </Step>
                <Step>
                  {({ accomplished }) => (
                    <div
                      style={{
                        background: accomplished
                          ? "rgb(33, 52, 80)"
                          : "#17a0d5",
                        height: "50px",
                        width: "50px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      3
                    </div>
                  )}
                </Step>
                <Step>
                  {({ accomplished }) => (
                    <div
                      style={{
                        background: accomplished
                          ? "rgb(33, 52, 80)"
                          : "#17a0d5",
                        height: "50px",
                        width: "50px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      4
                    </div>
                  )}
                </Step>
                <Step>
                  {({ accomplished }) => (
                    <div
                      style={{
                        background: accomplished
                          ? "rgb(33, 52, 80)"
                          : "#17a0d5",
                        height: "50px",
                        width: "50px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      5
                    </div>
                  )}
                </Step>
              </ProgressBar>
            </div>
          </div>
          <br />
          <br />
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div
                className="row pt-5 "
                style={{ background: "#e4e4e4", borderRadius: "10px" }}
              >
                <div className="col-md-12 mx-auto mb-5">{renderStep()}</div>
              </div>
              <div
                className="container row"
                style={{ position: "fixed", bottom: "10px", width: "100%" }}
              >
                {currentStep >= 1 && (
                  <div className="col-md-6 col-sm-12 mb-3 ">
                    <button
                      type="button"
                      className="btn-emision2-auto"
                      onClick={() =>
                        currentStep === 1
                          ? history.push(
                              "/planes-cotizacion/" +
                                encodeURIComponent(
                                  CryptoJS.AES.encrypt(
                                    JSON.stringify({
                                      datos: data,
                                      plan: plan,
                                    }),
                                    "clave_secreta"
                                  ).toString()
                                )
                            )
                          : setCurrentStep(currentStep - 1)
                      }
                    >
                      {currentStep === 1 ? "Volver" : "Anterior"}
                    </button>
                  </div>
                )}
                <div className="col-md-6 col-sm-12 mb-3 ">
                  <button
                    type="submit"
                    className="btn-emision-auto"
                    onClick={currentStep < totalSteps ? null : fetchEmision}
                  >
                    {currentStep < totalSteps ? "Siguiente" : emitiendo ? <Spinner/> : "Solicitar" }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="container pt-2">
          Enviamos la solicitud de emision poliza, enviaremos la respuesta a tu
          correo electrónico
        </div>
      )}
    </div>
  );
};

export default EmisionAuto;
