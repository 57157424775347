import React from 'react';
import img9 from "../../assets/img/ssn-w.png";
import './Footer.css';


function Footer(){
	return <>
		<div style={{ background: "#666666", padding: "20px" }}>
      <div className='container'>
          <div className="row pt-3 mb-5">
            <div className="col-md-9">
              <div className="text-start mb-3">
                <p style={{  color: "#fff" }}>
                  La entidad aseguradora dispone de un Servicio de Atención al
                  Asegurado que atenderá las consultas y reclamos que presenten
                  los tomadores de seguros, asegurados, beneficiarios y/o
                  derechohabientes.<br/><br/> El Servicio de Atención al Asegurado está
                  integrado por:<br/><strong>Responsable:</strong>  Marcelo José Luis Converso, Tel.
                  4789-3433, mail:converso@experta.com.ar.<br/><strong>Suplente:</strong>  María
                  Virginia Delfino, Tel. 4789-3441,
                  mail:virginia.delfino@experta.com.ar.<br/><br/> En caso de que existiera
                  un reclamo ante la entidad aseguradora y que el mismo no haya
                  sido resuelto o haya sido desestimado, total o parcialmente, o
                  que haya sido denegada su admisión, podrá comunicarse con la
                  Superintendencia de Seguros de la Nación por teléfono al
                  0800-666-8400, correo electrónico a consultas@ssn.gob.ar o
                  formulario disponible en la página argentina.gob.ar/ssn”.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-end">
              <a href="https://www.argentina.gob.ar/superintendencia-de-seguros">
                <img src={img9} alt="seguro" className="img-fluid" width="300"/>
              </a>
              <div className="text-end">
                <span style={{ fontSize: "12px", color: "#666" }}>
                  Nº de inscripción en SSN 0880
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
			</>
};



export default Footer;