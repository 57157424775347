"use client";
import React from "react";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa6";
import "./SeccionBaja.css";
const SeccionBaja = ({}) => {
  return (
    <>
      <div className="container SeccionBaja">
        <div className="row pt-5 mb-2">
          <h1>BOTÓN DE BAJA</h1>
        </div>
        <div className="row">
          <div className="mb-4 h4">
            Si contrataste tu seguro online y querés solicitar la baja, podés
            hacerlo completando el siguiente formulario.
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <a href="https://www.experta.com.ar/boton-baja/">
              <div className="btn  btn-segumas  mx-auto">
                <small>CLICK ACÁ</small>
              </div>
            </a>
          </div>

          <div className="row-app pt-4 mb-5">
            <div className="col-md-12 col-sm-12 text-start">
              <div className="mb-3 h6">
                EL ARTÍCULO 42 DE LA CONSTITUCIÓN NACIONAL ESTABLECE QUE LOS
                CONSUMIDORES Y USUARIOS DE BIENES Y SERVICIOS TIENEN DERECHO, EN
                LA RELACIÓN DE CONSUMO, A LA PROTECCIÓN DE SUS INTERESES
                ECONÓMICOS; A UNA INFORMACIÓN ADECUADA Y VERAZ; A LA LIBERTAD DE
                ELECCIÓN, Y A CONDICIONES DE TRATO EQUITATIVO Y DIGNO. BOTÓN DE
                BAJA CONSUMIDORES. PARA RECLAMOS{" "}
                <a href="https://autogestion.produccion.gob.ar/consumidores">
                  <span style={{ color: "#17a0d5" }}>HAGA CLICK AQUÍ.</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SeccionBaja.propTypes = {};

export default SeccionBaja;
