import { Redirect } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../auth/useAuth";
import axios from "axios";
import { url } from "../service/EndPoint";
import NavBarSeg from "../components/NavBarSeg/NavBarSeg";

export default function PublicRoute({ component: Component, ...rest }) {
  const { rootState } = useAuth();
  const { isAuth, showLogin } = rootState;
  const [view, setView] = useState(false);
  const [home, setHome] = useState(false);
  const [hogar, setHogar] = useState("");
  const [auto, setAuto] = useState("");
  const [formData, setFormData] = useState({
    direccion: "",
    telefono: "",
    telefonoWhatsapp: "",
    quienes: "",
    cuit: "",
    razonSocial: "",
    condicion: "",
    horario: "",
    email: "",
    background_home: "",
    background_home_mobile: "",
    facebook_url: "",
    instagram_url: "",
    background_hogar: "",
    background_hogar_mobile: "",
    promocion_hogar: "",
    background_auto: "",
    background_auto_mobile: "",
    promocion_auto: "",
  });
  function getData() {
    axios
      .post(url + "media/get/media.php")
      .then((getData) => {
        // Manejar la respuesta exitosa aquí
        setHome(getData.data.home[0]);
        setHogar(getData.data.hogar[0]);
        setAuto(getData.data.auto[0]);
      })
      .catch((error) => {
        // Manejar el error aquí
        console.log("Error al obtener datos:", error);
      });
  }
  function getDatos() {
    axios
      .post(url + "parametros/get/parametros.php")
      .then((getData) => {
        // Manejar la respuesta exitosa aquí
        setFormData(getData.data[0]);
      })
      .catch((error) => {
        // Manejar el error aquí
        console.log("Error al obtener datos:", error);
      });
  }

  useEffect(() => {
    getData();
    getDatos();
    setView(true);
  }, []);

  const datos = {
    home: home,
    hogar: hogar,
    auto: auto,
    informacion: formData,
  };

  if (isAuth) {
    return <Redirect to="/dashboard" />;
  } else if (showLogin) {
    return (
      <>
        {view ? (
          <>
         
          <Component context={datos} />
          </>
        ) : (
          <div className="container">
            <div className="pt-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
