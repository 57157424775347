"use client";
import { useEffect, useState } from "react";
import visa from "../../assets/img/5.png";
import master from "../../assets/img/4.png";
import amex from "../../assets/img/pngegg.png";
import cabal from "../../assets/img/cabal.png";
import diners from "../../assets/img/Diners.png";
import { BsArrowRepeat } from "react-icons/bs";
import "./Tarjeta.css";

const Tarjeta = (props) => {
  const titular = props.titular;
  const nume = props.nroTarjeta;
  const vol = props.voltear;
  const cv = props.cvv;
  const venc = props.venci;
  const tarje = props.tarjeta;

  const [voltear, setVoltear] = useState(vol);
  const [nombre, setNombre] = useState(titular || null);
  const [numero, setNumero] = useState(nume || null);
  const [tarjeta, setTarjeta] = useState(tarje || null);
  const [vencimiento, setVencimiento] = useState(venc || null);
  const [cvv, setCvv] = useState(cv || null);

  const comienzaCon = numero && Number(numero.toString()[0]);

  const formatText = (text) => {
    const formatted = text.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      "$1 $2 $3 $4"
    );
    return formatted;
  };

  useEffect(() => {
    setNumero(props.nroTarjeta);
  }, [nume]);
  useEffect(() => {
    setCvv(props.cvv);
  }, [cv]);
  useEffect(() => {
    setVencimiento(props.venci);
  }, [venc]);

  useEffect(() => {
    setNombre(props.titular);
  }, [titular]);
  useEffect(() => {
    setTarjeta(props.tarjeta);
  }, [tarje]);

  function onClickTarjeta() {
    if (voltear) {
      setVoltear(false);
    } else {
      setVoltear(true);
    }
  }

  const AMEX =
    "linear-gradient(90deg, rgba(48, 48, 48, 1) 0%, rgba(80, 80, 80, 1) 35%, rgba(166, 166, 166, 1) 100%)";
  const MASTER =
    "linear-gradient(90deg, rgba(48, 48, 48, 1) 0%, rgba(80, 80, 80, 1) 35%, rgba(166, 166, 166, 1) 100%)";
  const VISA = "linear-gradient(to right, #021670, #5168D1)";
  const NARANJA = "linear-gradient(to right, rgb(237 75 2), rgb(255 140 87))";
  const CABAL = "linear-gradient(to right, #97752B, #CFAD55)";
  const DINERS =
    "linear-gradient(to right, rgb(89 89 89), rgb(184 184 184))";

  return (
    <>
      <div className="tarjeta ">
        <div className="flip-container">
          <div
            className="card"
            style={{
              transform: voltear ? "rotateY(180deg)" : "none",
            }}
          >
            <div
              className="front"
              style={{
                background:
                  Number(tarjeta) === 70
                    ? AMEX
                    : Number(tarjeta) === 71
                    ? MASTER
                    : Number(tarjeta) === 72
                    ? CABAL
                    : Number(tarjeta) === 73
                    ? DINERS
                    : Number(tarjeta) === 74
                    ? VISA
                    : Number(tarjeta) === 75
                    ? NARANJA
                    : "linear-gradient(90deg, rgba(48, 48, 48, 1) 0%, rgba(80, 80, 80, 1) 35%, rgba(166, 166, 166, 1) 100%)",
              }}
            >
              <div className="text-end">
                <div className="tipoTarjeta">
                  {(() => {
                    const tipoDeTarjeta = Number(tarjeta);
                    switch (tipoDeTarjeta) {
                      case 70:
                        return <img src={amex} width={120} alt={amex} />;
                      case 71:
                        return <img src={master} width={125} alt={master} />;
                      case 72:
                        return <img src={cabal} width={70} alt={cabal} />;
                      case 73:
                        return <img src={diners} width={160} alt={diners} />;
                      case 74:
                        return <img src={visa} width={120} alt={visa} />;
                      case 75:
                        return (
                          <strong style={{ fontSize: "2rem" }}>NaranjaX</strong>
                        );
                      default:
                        return "";
                    }
                  })()}
                </div>
              </div>
              <div
                style={{
                  paddingTop: "50px",
                  textAlign: "center",
                }}
              >
                <span className="number">{numero && formatText(numero)}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignContent: "start",
                  flexDirection: "column",
                }}
              >
                <div className="text-start">
                  <p className="nombre">{nombre && nombre}</p>
                </div>
                <div className="hasta">
                  <span style={{ fontSize: "18px" }}>
                    {" "}
                    {vencimiento && vencimiento}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="back"
              style={{
                background:
                  Number(tarjeta) === 70
                    ? AMEX
                    : Number(tarjeta) === 71
                    ? MASTER
                    : Number(tarjeta) === 72
                    ? CABAL
                    : Number(tarjeta) === 73
                    ? DINERS
                    : Number(tarjeta) === 74
                    ? VISA
                    : Number(tarjeta) === 75
                    ? NARANJA
                    : "linear-gradient(90deg, rgba(48, 48, 48, 1) 0%, rgba(80, 80, 80, 1) 35%, rgba(166, 166, 166, 1) 100%)",
              }}
            >
              <div style={{ paddingTop: "30px" }}>
                <div className="cinta"></div>
                <div style={{ paddingTop: "15px" }}></div>
                <div className="code">
                  <span className="">{cvv && cvv}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className="btn-emision-auto"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2rem",
        }}
        onClick={() => onClickTarjeta()}
      >
        <BsArrowRepeat />
      </div>
    </>
  );
};

Tarjeta.propTypes = {};

export default Tarjeta;
