"use client";
import React, { useState } from "react";
import "./NavBarSeg.css";
import * as BsIcons from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa6";
import logo from "../../assets/Marca Segumas_Original.png";
import { NavLink } from "react-router-dom";

const NavBarSeg = ({}) => {
 
  const [menuSeguro, setMenuSeguro] = useState(false);

  const handleShow = () => {
    if (menuSeguro) {
      setMenuSeguro(true);
    } else {
    }
  };

  return (
    <div className="navbarseg">
      <nav className="nav d-none d-sm-none d-md-block fixed-top">
        <ul className="nav__menu">
          <li className="menu__logo">
            <NavLink
              exact
              to="/"
              className="link-segumas"
              style={{ fontSize: "13px" }}
            >
              <img src={logo} alt="Segumas" className="img-fluid" />
            </NavLink>
          </li>
          <li>
            <a
              className="nav-link link-segumas dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              SEGURO{' '}
              <BsIcons.BsChevronDown />
            </a>
            <ul className="dropdown-menu shadow" aria-labelledby="navbarDropdown">
              <li className="text-center">
                <NavLink exact to="/seguro-hogar" className="link-segumas">
                  HOGAR
                </NavLink>
              </li>
              <li className="text-center">
                <NavLink exact to="/seguro-auto" className="link-segumas">
                  AUTO
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink exact to="/quienes-somos" className="link-segumas">
              INSTITUCIONAL
            </NavLink>
          </li>
          <li>
            <a
              className="nav-link link-segumas dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              CONTACTO{' '}
              <BsIcons.BsChevronDown />
            </a>
            <ul className="dropdown-menu shadow" aria-labelledby="navbarDropdown">
              <li className="text-center">
                <NavLink
                  exact
                  to="/contactenos"
                  className="link-segumas"
                  style={{ fontSize: "13px" }}
                >
                  FORMULARIO
                  <br />
                  DE CONTACTO
                </NavLink>
              </li>

            </ul>
          </li>
          <li>
            <div className="search-input position-relative">
              <input
                type="search"
                className="form-control form-control-lg ps-5"
                placeholder="BUSCAR"
              />
              <BsIcons.BsSearch
                className="position-absolute top-50 translate-middle-y search-icon"
                style={{ fontSize: "13.2px" }}
              />
            </div>
          </li>
          <li>
            <a href="https://www.instagram.com/segumass/" target="_blank">
              <div className="icono-segumas">
                <BsIcons.BsInstagram />
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/profile.php?id=61553769747635" target="_blank">
              <div className="icono-segumas">
                <FaFacebookF />
              </div>
            </a>
          </li>
          <li>
            <NavLink exact to="/login" className="btn  btn-segumas">
              INGRESAR
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavBarSeg.propTypes = {};

export default NavBarSeg;
