import { useState, useEffect, useRef } from "react";
import { url } from "../../service/EndPoint";
import { NavLink, useHistory } from "react-router-dom";
import ButtonWhatsapp from "../../components/ButtonWhatsapp/ButtonWhatsapp";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../../components/Footer/Footer";
import NavbFooterHome from "../../components/NavFooterHome";
import CryptoJS from "crypto-js";
import "./SeguroAuto.css";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import SeccionAutoDescuento from "../../components/SeccionAutoDescuento/SeccionAutoDescuento";
import { IoCarSportSharp } from "react-icons/io5";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";

function SeguroAuto(props) {
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [cotizando, setCotizando] = useState(true);
  const [hayCotizacion, setHayCotizacion] = useState(
    localStorage.getItem("cotizacion") || null
  );
  const [cotizacion, setCotizacion] = useState({
    nombre: "",
    apellido: "",
    email: "",
    codigoPostal: "",
    codInfoAuto: "",
    marca: "",
    ceroKm: "N",
    modelo: "",
    anio: "",
    fechaVigencia: "",
    gnc: "N",
    iva: "5",
    modalidad: "EX0",
    version: "",
    productor: 26142,
    uso: "1",
  });
  
  const [modalidades,setModalidades]=useState(null)
  async function fetchModalidades() {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/modalidades-productor?productor=26142",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setModalidades(response.data);
    //  console.log(modalidades)
    } catch (error) {
    //  console.error("Error al obtener las formas de pago:", error);
    }
  }
  const [productor,setProductor] = useState(null)
  async function fetchProductor() { 
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/forma-pago?productor=26142",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProductor(response.data);
     // console.log(productor)
    } catch (error) {
    //  console.error("Error al obtener las formas de pago:", error);
    }
  }
  useEffect(() => {
    
    if (hayCotizacion) {
      let coti = JSON.parse(hayCotizacion);
      var fechaAComparar = new Date(coti.fechaCreacion);
      var fechaActual = new Date();

      
      if (
        fechaActual.getFullYear() === fechaAComparar.getFullYear() &&
        fechaActual.getMonth() === fechaAComparar.getMonth() &&
        fechaActual.getDate() === fechaAComparar.getDate()
      ) {
     //   console.log("Hay una cotizacion vigente");
      } else {
        localStorage.clear();
      }
    }
  }, []);

  const [token, setToken] = useState("");
  const [gncButton, setGncButton] = useState(true);

  const [anio, setAnio] = useState(2023);
  const [codPostal, setCodPostal] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [marca, setMarca] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [disabled1, setDisabled1] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);

  async function fetchToken() {
    try {
      const response = await axios.post(
        "https://autos-api.apiexperta.com.ar/login",
        {
          user: "segumas",
          password: "ae9950b87ffc3b372739ad468bb2a676",
        }
      );

      const { jwt } = response.data;
      setToken(jwt);
    } catch (error) {
    //  console.error("Error al obtener el token:", error);
    }
  }

  useEffect(() => {
    fetchToken();

    const intervalId = setInterval(() => {
      fetchToken();
    }, 900000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    async function fetchMarcas() {
      if (token) {
        try {
          const response = await axios.get(
            "https://autos-api.apiexperta.com.ar/commons/marcas?anio=" + anio,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setMarcas(response.data.marcas);
          setDisabled2(false);
        } catch (error) {
       //   console.error("Error al obtener las marcas:", error);
        }
      }
    }
    fetchProductor();
    fetchModalidades();
    fetchMarcas();
  }, [token, anio]);

  async function fetchModelos(e) {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/modelos?marca=" +
          e +
          "&anio=" +
          anio,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setModelos(response.data.modelos);
      setDisabled3(false);
    } catch (error) {
    //  console.error("Error al obtener las marcas:", error);
    }
  }

  const onChangeValue = (e) => {
    setCotizacion({
      ...cotizacion,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeValueCeroKm = (e) => {
    if (cotizacion.ceroKm === "N") {
      setCotizacion({
        ...cotizacion,
        [e.target.name]: "S",
      });
    } else {
      setCotizacion({
        ...cotizacion,
        [e.target.name]: "N",
      });
    }
  };

  const onChangeValueVersion = (e) => {
    const { value } = e.target;
    const { codigoInfoauto, descripcion } = JSON.parse(value);
    setCotizacion({
      ...cotizacion,
      codInfoAuto: codigoInfoauto,
      version: descripcion,
    });
  };
  async function fetchVersiones(e) {
    try {
      const response = await axios.get(
        "https://autos-api.apiexperta.com.ar/commons/versiones?marca=" +
          marca +
          "&modelo=" +
          e +
          "&anio=" +
          anio +
          "&ceroKm=" +
          cotizacion.ceroKm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setVersiones(response.data.versiones);
      setDisabled4(false);
    } catch (error) {
    //  console.error("Error al obtener las marcas:", error);
    }
  }

  const selectRef = useRef(null);
  const selectRef1 = useRef(null);
  const selectRef2 = useRef(null);
  const selectRef3 = useRef(null);
  const onClickGnc = () => {
    setGncButton(!gncButton);
    if (!gncButton) {
      setCotizacion({
        ...cotizacion,
        gnc: "N",
      });
      selectRef.current.value = "";
    }
  };

  useEffect(() => {
    async function fetchLocalidades() {
      if (codPostal.length === 4) {
        try {
          const response = await axios.get(
            "https://autos-api.apiexperta.com.ar/commons/localidades?codPostal=" +
              codPostal,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setLocalidades(response.data.localidades);
          setDisabled1(false);
        } catch (error) {
        //  console.error("Error al obtener las localidades:", error);
        }
      } else {
        setDisabled1(true);
      }
    } 

    fetchLocalidades();
  }, [codPostal]);

  function handleRecaptchaChange(value) {
    if (value) {
      setActivo(false);
    }
  }
  const [activo, setActivo] = useState(true);
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function validarCorreoElectronico(correo) {
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  }

  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Nota: ¡los meses comienzan desde 0!
  const year = currentDate.getFullYear();
  // Asegúrate de agregar un cero inicial si el día o el mes es menor a 10
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Formatea la fecha como "DD/MM/YYYY"
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

  async function realizarCotizacion() {
    let datosEnviar = { 
      productor: 26142,
      iva: cotizacion.iva,
      modalidad: "EX0", //el tipo de comision
      codInfoAuto: cotizacion.codInfoAuto,
      codigoPostal: cotizacion.codigoPostal,
      anio: cotizacion.anio,
      ceroKM: cotizacion.ceroKm,
      gnc: cotizacion.gnc,
      fechaVigencia: formattedDate,
      apellido: cotizacion.apellido,
      nombres: cotizacion.nombre,
      uso: cotizacion.uso,
      email: cotizacion.email,
      marca: cotizacion.marca,
      modelo: cotizacion.modelo,
      version: cotizacion.version,
    };

    try {
      const response = await axios.post(
        "https://autos-api.apiexperta.com.ar/cotizaciones/",
        datosEnviar,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "api-key": "a56b244a-b869-4a00-a88a-e89ffe6a2bb4",
          },
        }
      );
      axios
        .post(url + `cotizaciones/auto/add.php`, cotizacion)
        .then((res) => {})
        .catch(function (res) {});
      localStorage.setItem("cotizacion", JSON.stringify(response.data));
      localStorage.setItem("cotizacionDatos", JSON.stringify(datosEnviar));
      history.push(
        "/planes-cotizacion/" +
          encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify({ datos: response.data, datosEnviar }),
              "clave_secreta"
            ).toString()
          )
      );
      // Puedes retornar los datos o realizar otras acciones según sea necesario
    } catch (error) {
      // Manejo de errores
     // console.error("Error al obtener las cotizaciones:", error);
      axios
        .post(url + `cotizaciones/auto/add.php`, cotizacion)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function enviar() {
    setActivar(true);
    if (!validarCorreoElectronico(cotizacion.email)) {
      setActivar(true);
      alert(
        "Por favor, debes completar los campos y ingresar un correo valido"
      );
    } else {
      setCotizando(false);
      scrollToTop();
      realizarCotizacion();
    }
  }
  return (
    <>
      
      <NavBarSeg className="d-none d-sm-none d-md-block"/>
      <div className="d-none d-sm-none d-md-block" style={{paddingTop:'80px'}}></div>
      <div className=" seguroauto" >
      
        <div className="fondo-hogar pt-5">
          <div className="fondo-hogar-logo">
          <IoCarSportSharp  className="icono-hogar"/>
            <h1>
              SEGURO <br />
              AUTO
            </h1>
          </div>
        </div>
        <div className="pt-5 text-center mb-5  d-sm-block d-md-none">
              <h1 style={{fontStyle:'italic'}}>ASEGURAMOS TU AUTO, BRINDÁNDOTE<br/> UNA COBERTURA COMPLETA.</h1>
       </div>
       <div className="pt-5 text-center mb-5 d-none d-sm-none d-md-block">
              <h1 style={{fontStyle:'italic',fontSize:'50px'}}>ASEGURAMOS TU AUTO, BRINDÁNDOTE<br/> UNA COBERTURA COMPLETA.</h1>
       </div>
       <SeccionAutoDescuento />
       <div className="container pt-5">
       <NavbFooterHome />
        <ButtonWhatsapp info={props.context.informacion} />
          <div className="row pt-5">
              <span className="mb-5" style={{fontSize:'50px'}}>UN SEGURO QUE LO HACE SIMPLE</span>
            <div className=" pt-3">
              {!hayCotizacion ? (
                <div>
                  {cotizando ? (
                    <>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="container-formulario text-start">
                            <h1> Datos de contacto</h1>
                            <form>
                              <div className="row">
                                <div className="row g-2">
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="nombre"
                                        placeholder="NOMBRE"
                                        onChange={onChangeValue}
                                      />
                                      <label htmlFor="Nombre">NOMBRE</label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="apellido"
                                        placeholder="APELLIDO"
                                        onChange={onChangeValue}
                                      />
                                      <label htmlFor="Apellido">APELLIDO</label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="row g-2">
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="dni"
                                        placeholder="DNI"
                                        pattern="[0-9]+"
                                        minLength={6}
                                        maxLength={10}
                                        onChange={onChangeValue}
                                      />
                                      <label htmlFor="DNI">DNI</label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="telefono"
                                        placeholder="01143214321"
                                        pattern="[0-9]+"
                                        minLength={6}
                                        maxLength={15}
                                        onChange={onChangeValue}
                                      />
                                      <label htmlFor="CELULAR">CELULAR</label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="row g-2">
                                  <div className="form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="email"
                                      placeholder="E.MAIL"
                                      onChange={(e) => onChangeValue(e)}
                                    />
                                    <label htmlFor="mail">E.MAIL</label>
                                    <div className="invalid-feedback">
                                      Por favor completa este campo
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="row g-2">
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="codigo"
                                        name="codigoPostal"
                                        placeholder="1234"
                                        minLength={4}
                                        maxLength={4}
                                        onChange={(e) => {
                                          onChangeValue(e);
                                          setCodPostal(e.target.value);
                                        }}
                                      />
                                      <label htmlFor="codigo">
                                        CÓD. POSTAL
                                      </label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="form-floating">
                                      <select
                                        className="form-select"
                                        name="localidad"
                                        disabled={disabled1}
                                        onChange={onChangeValue}
                                      >
                                        <option></option>
                                        {localidades &&
                                          localidades.map((data, index) => (
                                            <option
                                              key={index}
                                              value={data.codPostal}
                                            >
                                              {data.localidad}
                                            </option>
                                          ))}
                                      </select>
                                      <label htmlFor="LOCALIDAD">
                                        LOCALIDAD
                                      </label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="row g-2">
                                  <div className="form-floating">
                                    <select
                                      className="form-select"
                                      id="condicion"
                                      name="iva"
                                      aria-label="CONDICIÓN IMPOSITIVA"
                                      onChange={onChangeValue}
                                    >
                                      <option id="Consumidor Final" value={5}>
                                        CONSUMIDOR FINAL
                                      </option>
                                      <option id="Exento" value={4}>
                                        EXENTO
                                      </option>
                                      <option id="Gran Contribuyente" value={2}>
                                        GRAN CONTRIBUYENTE
                                      </option>
                                      <option
                                        id="IVA No Categorizados RG.212"
                                        value={8}
                                      >
                                        IVA NO CATEGORIZADOS RG.212
                                      </option>
                                      <option id="Monotributo" value={7}>
                                        MONOTRIBUTO
                                      </option>
                                      <option
                                        id="Responsable Inscripto"
                                        value={1}
                                      >
                                        RESPONSABLE INSCRIPTO
                                      </option>
                                      <option id="Tierra del Fuego" value={6}>
                                        TIERRA DEL FUEGO
                                      </option>
                                    </select>
                                    <label htmlFor="condicion">
                                      CONDICIÓN IMPOSITIVA
                                    </label>
                                    <div className="invalid-feedback">
                                      Por favor completa este campo
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <div className="container-formulario text-start">
                            <h1>Datos del vehículo</h1>

                            <div className="row">
                              <form>
                                <div className="row">
                                  <div className="row g-2">
                                    <div className="col-md">
                                      <div
                                        style={{
                                          background: "#fff",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="ceroKm"
                                            id="flexCheckDefault"
                                            onChange={onChangeValueCeroKm}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                          >
                                            0KM
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <select
                                          className="form-select"
                                          id="year"
                                          name="anio"
                                          onChange={(e) => {
                                            onChangeValue(e);
                                            setAnio(e.target.value);
                                            selectRef1.current.value = "";
                                            selectRef2.current.value = "";
                                            selectRef3.current.value = "";
                                            setDisabled3(true);
                                            setDisabled4(true);
                                          }}
                                        >
                                          <option />
                                          <option id={2023} value={2023}>
                                            2023
                                          </option>
                                          <option id={2022} value={2022}>
                                            2022
                                          </option>
                                          <option id={2021} value={2021}>
                                            2021
                                          </option>
                                          <option id={2020} value={2020}>
                                            2020
                                          </option>
                                          <option id={2019} value={2019}>
                                            2019
                                          </option>
                                          <option id={2018} value={2018}>
                                            2018
                                          </option>
                                          <option id={2017} value={2017}>
                                            2017
                                          </option>
                                          <option id={2016} value={2016}>
                                            2016
                                          </option>
                                          <option id={2015} value={2015}>
                                            2015
                                          </option>
                                          <option id={2014} value={2014}>
                                            2014
                                          </option>
                                          <option id={2013} value={2013}>
                                            2013
                                          </option>
                                          <option id={2012} value={2012}>
                                            2012
                                          </option>
                                          <option id={2011} value={2011}>
                                            2011
                                          </option>
                                          <option id={2010} value={2010}>
                                            2010
                                          </option>
                                          <option id={2009} value={2009}>
                                            2009
                                          </option>
                                          <option id={2008} value={2008}>
                                            2008
                                          </option>
                                          <option id={2007} value={2007}>
                                            2007
                                          </option>
                                          <option id={2006} value={2006}>
                                            2006
                                          </option>
                                          <option id={2005} value={2005}>
                                            2005
                                          </option>
                                          <option id={2004} value={2004}>
                                            2004
                                          </option>
                                          <option id={2003} value={2003}>
                                            2003
                                          </option>
                                          <option id={2002} value={2002}>
                                            2002
                                          </option>
                                          <option id={2001} value={2001}>
                                            2001
                                          </option>
                                          <option id={2000} value={2000}>
                                            2000
                                          </option>
                                          <option id={1999} value={1999}>
                                            1999
                                          </option>
                                          <option id={1998} value={1998}>
                                            1998
                                          </option>
                                          <option id={1997} value={1997}>
                                            1997
                                          </option>
                                          <option id={1996} value={1996}>
                                            1996
                                          </option>
                                          <option id={1995} value={1995}>
                                            1995
                                          </option>
                                          <option id={1994} value={1994}>
                                            1994
                                          </option>
                                          <option id={1993} value={1993}>
                                            1993
                                          </option>
                                          <option id={1992} value={1992}>
                                            1992
                                          </option>
                                          <option id={1991} value={1991}>
                                            1991
                                          </option>
                                          <option id={1990} value={1990}>
                                            1990
                                          </option>
                                          <option id={1989} value={1989}>
                                            1989
                                          </option>
                                          <option id={1988} value={1988}>
                                            1988
                                          </option>
                                          <option id={1987} value={1987}>
                                            1987
                                          </option>
                                          <option id={1986} value={1986}>
                                            1986
                                          </option>
                                          <option id={1985} value={1985}>
                                            1985
                                          </option>
                                          <option id={1984} value={1984}>
                                            1984
                                          </option>
                                          <option id={1983} value={1983}>
                                            1983
                                          </option>
                                          <option id={1982} value={1982}>
                                            1982
                                          </option>
                                          <option id={1981} value={1981}>
                                            1981
                                          </option>
                                          <option id={1980} value={1980}>
                                            1980
                                          </option>
                                          <option id={1979} value={1979}>
                                            1979
                                          </option>
                                          <option id={1978} value={1978}>
                                            1978
                                          </option>
                                          <option id={1977} value={1977}>
                                            1977
                                          </option>
                                          <option id={1976} value={1976}>
                                            1976
                                          </option>
                                          <option id={1975} value={1975}>
                                            1975
                                          </option>
                                          <option id={1974} value={1974}>
                                            1974
                                          </option>
                                          <option id={1973} value={1973}>
                                            1973
                                          </option>
                                        </select>
                                        <label htmlFor="ANO">AÑO</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="row g-2">
                                    <div className="col-md">
                                      <div
                                        style={{
                                          background: "#fff",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onClick={onClickGnc}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="gnc"
                                          >
                                            GNC
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <select
                                          className="form-select"
                                          name="gnc"
                                          onChange={onChangeValue}
                                          ref={selectRef}
                                          disabled={gncButton}
                                        >
                                          <option />
                                          <option id={501} value={501}>
                                            MENOS DE 90 LITROS
                                          </option>
                                          <option id={502} value={502}>
                                            DE 90 LITROS A 120 LITROS
                                          </option>
                                          <option id={503} value={503}>
                                            MÁS DE 120 LITROS
                                          </option>
                                        </select>
                                        <label htmlFor="gnc">GNC</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="row g-2">
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <select
                                          className="form-select"
                                          id="marca"
                                          name="marca"
                                          disabled={disabled2}
                                          ref={selectRef1}
                                          onChange={(e) => {
                                            onChangeValue(e);
                                            setMarca(e.target.value);
                                            fetchModelos(e.target.value);
                                            selectRef2.current.value = "";
                                            selectRef3.current.value = "";
                                            setDisabled4(true);
                                          }}
                                        >
                                          <option></option>
                                          {marcas &&
                                            marcas.map((data, index) => (
                                              <option
                                                key={index}
                                                value={data.descripcion}
                                              >
                                                {data.descripcion}
                                              </option>
                                            ))}
                                        </select>
                                        <label htmlFor="marca">MARCA</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <select
                                          className="form-select"
                                          id="modelo"
                                          name="modelo"
                                          disabled={disabled3}
                                          ref={selectRef2}
                                          onChange={(e) => {
                                            onChangeValue(e);
                                            fetchVersiones(e.target.value);
                                            selectRef3.current.value = "";
                                          }}
                                        >
                                          <option></option>
                                          {modelos &&
                                            modelos.map((data, index) => (
                                              <option
                                                key={index}
                                                value={data.descripcion}
                                              >
                                                {data.descripcion}
                                              </option>
                                            ))}
                                        </select>
                                        <label htmlFor="modelo">MODELO</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="row g-2">
                                    <div className="form-floating">
                                      <select
                                        className="form-select"
                                        id="version"
                                        name="version"
                                        disabled={disabled4}
                                        ref={selectRef3}
                                        onChange={(e) => {
                                          onChangeValueVersion(e);
                                        }}
                                      >
                                        <option></option>
                                        {versiones &&
                                          versiones.map((data, index) => (
                                            <option
                                              key={index}
                                              value={JSON.stringify(data)}
                                            >
                                              {data.descripcion}
                                            </option>
                                          ))}
                                      </select>
                                      <label htmlFor="version">VERSIÓN</label>
                                      <div className="invalid-feedback">
                                        Por favor completa este campo
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="row g-2">
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <select
                                          className="form-select"
                                          id="uso"
                                          name="uso"
                                          aria-label="USO"
                                          onChange={(e) => {
                                            onChangeValue(e);
                                          }}
                                        >
                                          <option value={1}>PARTICULAR</option>
                                          <option value={10}>COMERCIAL</option>
                                        </select>
                                        <label htmlFor="uso">USO</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="patente"
                                          name="patente"
                                          placeholder="PATENTE"
                                          onChange={(e) => {
                                            onChangeValue(e);
                                          }}
                                        />
                                        <label htmlFor="patente">PATENTE</label>
                                        <div className="invalid-feedback">
                                          Por favor completa este campo
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="text-center">
                        <ReCAPTCHA
                          style={{ display: "flex", justifyContent: "center" }}
                          sitekey="6LeURH0oAAAAAEcZSiDZVhyjiSN7mFwhmRDFLGpb"
                          onChange={handleRecaptchaChange}
                        /><br/>
                        <button
                          className="btn btn-segumas mx-auto"
                          disabled={activo}
                          onClick={() => activar && enviar()}
                        >
                          SOLICITAR
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {exito ? (
                        <div className="h3 text-center pt-5">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="h3 text-center pt-5">
                          En breve nos comunicaremos
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div style={{ background: "#eeeeef", padding: "20px" }}>
                  Tienes una cotización
                  <br />
                  <NavLink
                    exact
                    to={
                      "/planes-cotizacion/" +
                      encodeURIComponent(
                        CryptoJS.AES.encrypt(
                          JSON.stringify({ datos: JSON.parse(hayCotizacion) }),
                          "clave_secreta"
                        ).toString()
                      )
                    }
                  >
                    <button className="btn btn-segumas">VER</button>
                  </NavLink>
                  <br />
                  <button
                    className="btn btn-segumas"
                    onClick={() => {
                      setHayCotizacion(null);
                      localStorage.removeItem("cotizacion");
                    }}
                  >
                    REALIZAR UNA NUEVA
                  </button>
                </div>
              )}
            </div>
          </div>
    </div>
      </div>
      <br />
      <br />
      <SeccionAtencion />
      <Footer />
    </>
  );
}
export default SeguroAuto;
