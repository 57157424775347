import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ConsultaModalChat.css";
import * as BsIcons from "react-icons/bs";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import { url } from "../../service/EndPoint";
import axios from "axios";

function ConsultaModalChat({ show, onHide, data, actualizar }) {
  const history = useHistory();
  const [buscar, setBuscar] = useState(true);
  const [color, setColor] = useState(false);
  const [datos, setDatos] = useState([]);

  function generarClaveAleatoria(e) {
    const longitud = 10;
    const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let claveAleatoria = "";

    for (let i = 0; i < longitud; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      claveAleatoria += caracteres.charAt(indice);
    }
    setDatos({
      ...datos,
      nombre: e,
      codigo: claveAleatoria,      
    });
  }

  const clickChat = (e) => {
    e.preventDefault();
    console.log(datos)
    axios
	  .post(url + `mensajeria/add/add.php`, datos)
	  .then((res) => {			
		let data = res.data;
	if (Number(data.success) === 1) { 
      setBuscar(false);  
      let variable = {
        nombre: datos.nombre,
        id_emisor: datos.codigo,
        id_receptor: 1
      } 		
		  history.push(
        "/asistente/" +
          encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify(variable),
              "clave_secreta"
            ).toString()
          )
      );
		  		
		}else {
      setColor(true);
    }
	  })
	  .catch(function (res) {});
	  

  };

  return (
    <>
      <Modal show={show} onHide={onHide} dialogClassName="chat-modal">
        <Modal.Header style={{ background: "#17a0d5", color: "#fff" }}>
          <Modal.Title>ASISTENTE VIRTUAL</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "300px" }}>
          {buscar ? (
            <div style={{ color: !color ? "#000" : "red" }}>
              Hola por favor ingresa tu nombre
            </div>
          ) : (
            <div>Buscando asesor...</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="input-group  ">
            <input
              className="form-control"
              type="text"
              placeholder="Ingresa tu nombre"
              onChange={(e) => generarClaveAleatoria(e.target.value)}
              required
            />

            <BsIcons.BsSend
              style={{ fontSize: "2rem", cursor: "pointer" }}
              onClick={clickChat}
            />
          </div>
        </Modal.Footer>
        <div className="button-close" onClick={onHide}>
          <BsIcons.BsXLg className="font-17" />
        </div>
      </Modal>
    </>
  );
}

export default ConsultaModalChat;
