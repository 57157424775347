import { useState, useRef, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import "./AsistenteVirtual.css";
import { NavLink, useParams } from "react-router-dom";
import { url } from "../../service/EndPoint";
import CryptoJS from "crypto-js";
import mp3 from "./beep.mp3";

const AsistenteVirtual = ({}) => {
  const urls = window.location.href;
  const id = urls.split("/").pop();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [leido, setLeido] = useState(false);
  const [chatCerrado, setChatCerrado] = useState(false);
  const [receivedMessagesCount, setReceivedMessagesCount] = useState(0);
  const inputRef = useRef(null);
  const bottomRef = useRef(null);

  function envioMensaje() {
    if (newMessage.length > 0) {
      setMessages([
        ...messages,
        {
          id: "1",
          id_emisor: info.id_emisor,
          id_estado_mensaje: "1",
          id_receptor: info.id_receptor,
          mensaje: newMessage,
        },
      ]);

      inputRef.current.focus();

      inputRef.current.value = "";
      const formData = {
        id_emisor: info.id_emisor,
        mensaje: newMessage,
        id_receptor: info.id_receptor,
      };

      axios
        .post(url + `mensajeria/add/mensaje.php`, formData)
        .then((res) => {
          let data = res.data;
          if (Number(data.success) === 1) {
            scrollToBottom();
            setNewMessage("");
          }
        })
        .catch(function (res) {});
    }
  }
  function getData() {
    const formData = {
      id_emisor: info.id_emisor,
      id_receptor: info.id_receptor,
    };

    axios
      .post(url + `mensajeria/list/mensajes.php`, formData)
      .then((res) => {
        let data = res.data;

        if (Number(data.success) === 1) {
          if (data.datos) {
            if (messages.length !== data.datos.length) {
              setMessages(data.datos);			  
              setReceivedMessagesCount(data.datos.length);
              scrollToBottom();
              setInterval(() => {
                setLeido(true);
              }, 5000);
            }
          }
        }
      })
      .catch(function (res) {});
  }
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(url + `mensajeria/list/estado.php`, {
        params: {
          id: info.id_emisor,
        },
      });

      if (Number(response.data.datos[0].estado) === 2) {
        setChatCerrado(true);
      }
    } catch (error) {
      // Manejar el error aquí
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango de 2xx
    //   console.error("Respuesta del servidor:", error.response.data);
      //  console.error("Código de estado HTTP:", error.response.status);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
     //   console.error("No se recibió respuesta del servidor");
      } else {
        // Ocurrió un error durante la configuración de la solicitud
      //  console.error("Error al configurar la solicitud:", error.message);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
      scrollToBottom();
      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (receivedMessagesCount > messages.length) {
      playBeepSound();
    }
  }, [receivedMessagesCount, messages]);
  const playBeepSound = () => {
    const beepAudio = new Audio(mp3);
    beepAudio.play();
  };
  return (
    <>
      <div className="text-start mb-5 fixed-top">
        <div className="header-chat">
          <NavLink exact to={"/"}>
            <BsIcons.BsArrowLeft style={{ fontSize: "2rem", color: "#fff" }} />
          </NavLink>

          <div className="symbol-group  flex-nowrap ">
            <div className="profile-pic"></div>
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <div
          className="card-body messages-container msj-scrollable"
          style={{
            paddingTop: "80px",
            background: "#f3f3f3",
            paddingBottom: "10px",
          }}
        >
          <div className=" mb-2">
            <div className={"msj-recived"}>
              <div className="text-start">
                Hola como estas <strong>{info.nombre}</strong> en que podemos
                ayudarte.
              </div>
              <div className="text-end text-muted">
                <span style={{ fontSize: "0.7rem" }}>
                  {moment().format("HH:mm")}
                </span>
              </div>
            </div>
          </div>

          {messages &&
            messages.map((data, i) => {
              return (
                <div className=" mb-2" key={i}>
                  <div
                    className={
                      data.id_emisor !== info.id_emisor
                        ? "msj-recived"
                        : "msj-send"
                    }
                  >
                    <div className="text-start">{data.mensaje}</div>
                    <div className="text-end text-muted">
                      <span style={{ fontSize: "0.7rem" }}>
                        {moment(data.created_at).format("HH:mm")}
                      </span>{" "}
                      {data.id_emisor === info.id_emisor && leido ? (
                        <BsIcons.BsCheckAll style={{ color: "#17a0d5" }} />
                      ) : (
                        <BsIcons.BsCheck />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
			{chatCerrado &&
			<div className=" mb-2">
            <div className={"msj-recived"}>
              <div className="text-center bg-danger text-white">
                Chat Finalizado
              </div>
            </div>
          </div>
			}
          <div ref={bottomRef} onLoad={scrollToBottom} />
        </div>
        <div
          className="fixed-bottom"
          style={{ padding: "10px", background: "#fff" }}
        >
          <div className="container ">
            <div className="input-group  ">
              <input
                type="text"
                className="form-control"
                placeholder="Escribe un mensaje aqui"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => setNewMessage(e.target.value)}
                ref={inputRef}
              />
              <BsIcons.BsSend
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  envioMensaje();
                  scrollToBottom();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsistenteVirtual;
