import React from "react";

import Footer from "../../components/Footer/Footer";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";

function Quienes(props) {
 const data = props.context.informacion
  return (
    <>
     <NavBarSeg className="d-none d-sm-none d-md-block"/>
    <div style={{height:'100vh', paddingTop: '80px'}}>
      <div className="container">
        <div className="text-center pt-5">
        <h1>¿Quiénes somos?</h1>
        </div>
        
        <div className="text-start pt-3 " style={{padding:'30px',minHeight:'60vh'}}>
        {data && <div dangerouslySetInnerHTML={{ __html: data.quienes}}></div>}
        </div>
      
      </div>
      <SeccionAtencion />
      <Footer />
    </div>
    </>
  );
}

export default Quienes;
