import { useState } from "react";
import "./Contacto.css";
import NavHeader from "../../components/NavHeader/NavHeader";
import Footer from "../../components/Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { url } from "../../service/EndPoint";
import axios from "axios";
import NavbFooterHome from "../../components/NavFooterHome"
import SeccionBaja from "../../components/SeccionBaja/SeccionBaja";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";
function Contacto() {
  const [consulta, setConsulta] = useState("");

  const onChangeValue = (e) => {
    setConsulta({
      ...consulta,
      [e.target.name]: e.target.value,
    });
  };

  const [activo,setActivo]=useState(false)
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function handleRecaptchaChange(value) {
    if (value) {
      setActivo(false);
    }
  }
  function validarCorreoElectronico(correo) {    
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;    
    return expresionRegular.test(correo);
  }
 
  function enviar() {
    setActivar(false);
    if (
      consulta.nombre === "" ||
      consulta.apellido === "" ||
      consulta.cpa === "" ||
      consulta.calle === "" ||
      consulta.comentario === "" ||
      consulta.dni === "" ||
      consulta.telefono === "" ||
      consulta.numero === "" ||
      !validarCorreoElectronico(consulta.email)
    ) {
      setActivar(true);
      alert(
        "Por favor, debes completar los campos e ingresar un correo válido"
      );
    } else {
  
      axios
        .post(url + `consulta/create/add.php`, consulta)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }

  return (
    <>
     <NavBarSeg className="d-none d-sm-none d-md-block"/>
    <div className="contacto" style={{paddingTop:'80px'}}>
      <NavbFooterHome/>
      <div className="container">

        <div className="text-app pt-5">
          <div className="text-start mb-4">
            <div className="text-center">
            <h1>Contactanos</h1>
           
           
            <h4>
              Estamos cerca para brindarte una {" "}
              <strong>solución a tiempo</strong>
            </h4>
            </div>
          </div>
          <div className="container">
            <div className="row mb-5">
              <div
                className="col-md-6 col-sm-12 mx-auto"
                style={{ background: "#dedede", padding: "20px" }}
              >
                <div>
                  <span className="h3 text-app">FORMULARIO DE CONTACTO</span>
                  {exito ? (
                    <form>
                      <div className="row">
                        <div className="row g-2">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="nombre"
                                placeholder="NOMBRE"
                                onChange={onChangeValue}
                              />
                              <label htmlFor="Nombre">NOMBRE</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="apellido"
                                placeholder="APELLIDO"
                                onChange={onChangeValue}
                              />
                              <label htmlFor="Apellido">APELLIDO</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row g-2">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="dni"
                                placeholder="DNI"
                                pattern="[0-9]+"
                                minLength={6}
                                maxLength={10}
                                onChange={onChangeValue}
                              />
                              <label htmlFor="DNI">DNI</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="telefono"
                                placeholder="01143214321"
                                pattern="[0-9]+"
                                minLength={6}
                                maxLength={15}
                                onChange={onChangeValue}
                              />
                              <label htmlFor="CELULAR">CELULAR</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row g-2">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="E.MAIL"
                              onChange={(e) => onChangeValue(e)}
                            />
                            <label htmlFor="mail">E.MAIL</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row g-2">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="calle"
                                placeholder="CALLE"
                                onChange={onChangeValue}
                              />
                              <label htmlFor="calle">CALLE</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="numero"
                                placeholder="NUMERO"
                                onChange={onChangeValue}
                              />
                              <label htmlFor="numero">NUMERO</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="cpa"
                                placeholder="C.POSTAL"
                                onChange={onChangeValue}
                              />
                              <label htmlFor="cpa">C. POSTAL</label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-2">
                          <div className="form-floating">
                            <textarea
                              type="text-a"
                              className="form-control"
                              name="comentario"
                              placeholder="CONSULTA"
                              style={{ height: "100px" }}
                              onChange={(e) => onChangeValue(e)}
                            ></textarea>
                            <label htmlFor="comentario">CONSULTA</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                        <div className="pt-3 mb-3">
                          <ReCAPTCHA
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            sitekey="6LeURH0oAAAAAEcZSiDZVhyjiSN7mFwhmRDFLGpb"
                            onChange={handleRecaptchaChange}
                          />
                          <div className="mb-3 pt-3 text-start">
                            <span style={{ fontSize: "0.7rem" }}>
                              Los datos se recolectan únicamente para ser
                              utilizados con motivo de la relación comercial que
                              te vincula/rá con la Compañía (Art. 6 Ley
                              25.326/00). Disposición 10/08, Dirección Nacional
                              de Protección de Datos Personales, Art. 1 "El
                              titular de los datos personales tiene la facultad
                              de ejercer el derecho de acceso a los mismos en
                              forma gratuita a intervalos no inferiores a seis
                              meses, salvo que se acredite un interés legítimo
                              al efecto conforme lo establecido en el artículo
                              14, inciso 3 de la Ley N° 25.326". Art. 2 "La
                              DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS
                              PERSONALES, Órgano de Control de la Ley N° 25.326,
                              tiene la atribución de atender las denuncias y
                              reclamos que se interpongan con relación al
                              incumplimiento de las normas sobre protección de
                              datos personales.
                            </span>
                          </div>
                          <button
                            className="btn-contact-auto"
                            type="button"
                            disabled={activo}
                            onClick={() =>activar && enviar()}
                          >
                            ENVIAR
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="h3 text-center pt-5">Enviamos tu consulta !</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <SeccionAtencion/>
      <Footer/>
    </div>
    </>
  );
}

export default Contacto;
