import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/login/Login";
import Restore from "../pages/restore/Restore";
import Home from "../pages/home/Home";
import NotFoundPage from "../pages/404/NotFoundPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import SeguroHogar from "../pages/SeguroHogar/SeguroHogar";
import SeguroAuto from "../pages/SeguroAuto/SeguroAuto";
import Quienes from "../pages/Quienes/Quienes";
import Baja from "../pages/Baja/Baja";
import Donde from "../pages/Donde/Donde";
import Formularios from "../pages/Formularios/Formularios";
import Contacto from "../pages/Contacto/Contacto";
import ListaConsultas from "../pages/ListaConsultas/ListaConsultas";
import ListaCotizacionAuto from "../pages/ListaCotizacionAuto/ListaCotizacionAuto";
import ListaCotizacionHogar from "../pages/ListaCotizacionHogar/ListaCotizacionHogar";
import AsistenteVirtual from "../pages/AsistenteVirtual/AsistenteVirtual"
import ListaChat from "../pages/ListaChat/ListaChat";
import Asistente from "../pages/Asistente/Asistente";
import Parametros from "../pages/Parametros/Parametros";
import Media from "../pages/Media/Media";
import Cotizacion from "../pages/Cotizacion/Cotizacion";
import EmisionAuto from "../pages/EmisionAuto/EmisionAuto";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/seguro-auto" component={SeguroAuto} />
        <PublicRoute exact path="/seguro-hogar" component={SeguroHogar} />
        <PublicRoute exact path="/quienes-somos" component={Quienes} />
        <PublicRoute exact path="/boton-baja" component={Baja} />
        <PublicRoute exact path="/donde-estamos" component={Donde} />
        <PublicRoute exact path="/formularios" component={Formularios} />
        <PublicRoute exact path="/restore" component={Restore} />
        <PublicRoute exact path="/contactenos" component={Contacto} />
        <PublicRoute exact path="/asistente/:id" component={AsistenteVirtual} />
        <PublicRoute exact path="/planes-cotizacion/:id" component={Cotizacion} />
        <PublicRoute exact path="/emision-cotizacion/:id" component={EmisionAuto} />
        <Route path="/404" component={NotFoundPage} />        
        <PrivateRoute exact path="/dashboard" component={Dashboard} />        
        <PrivateRoute exact path="/parametros" component={Parametros} />
        <PrivateRoute exact path="/media" component={Media} />
        <PrivateRoute exact path="/consultas/lista" component={ListaConsultas} />
        <PrivateRoute exact path="/lista-cotizaciones/auto" component={ListaCotizacionAuto} />
        <PrivateRoute exact path="/lista-chat" component={ListaChat} />
        <PrivateRoute exact path="/lista-chat/chat/:id" component={Asistente} />
        <PrivateRoute exact path="/lista-cotizaciones/hogar" component={ListaCotizacionHogar} />
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Router>
  );
}
