"use client";
import React from "react";
import "./SeccionNuestros.css";
import { NavLink } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import { IoCarSportSharp } from "react-icons/io5";

const SeccionNuestros = ({}) => {
  return (
    <div className="seccionnuestros pt-5 mb-5">
      <div className="container">
        <h1>NUESTROS SEGUROS</h1>
        <div className="row pt-5 mx-auto">
          <div className="col-md-6 col-sm-12 mb-3 ">
            <NavLink exact to="/seguro-hogar">
              <div className="box-nuestros shadow">
              <TiHome className="icono-hogar"/>
                       
                <h1>HOGAR</h1>
              </div>
            </NavLink>
          </div>
          <div className="col-md-6 col-sm-12 mb-3  ">
            <NavLink exact to="/seguro-auto">
              <div className="box-nuestros shadow">
              <IoCarSportSharp  className="icono-hogar"/>
                <h1>AUTOS</h1>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SeccionNuestros.propTypes = {};

export default SeccionNuestros;
