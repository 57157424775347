import React from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import Footer from "../../components/Footer/Footer";


function Formularios() {
  return (
    <div>

      <div className="container">
        <NavHeader />
        <Footer />
      </div>
    </div>
  );
}

export default Formularios;
