"use client";
import React from "react";
import "./SeccionPromohogar.css";
import PropTypes from "prop-types";

const SeccionPromohogar = ({}) => {
  return (
    <div className="seccionpromohogar">
      <div className="container box-promo d-none d-sm-none d-md-block ">
        <div  style={{display:'flex', justifyContent:'center', alignItems:'center',height:'386px'}}>
        <div className="col  text-start">
          <span style={{ fontSize: "62px" }}>
            UN SEGURO
            <br /> QUE TE BRINDA
            <br /> TRANQUILIDAD
          </span>
        </div>
        <div className="col">
        <span style={{ fontSize: "45px" }}>POR MES DESDE</span><br/>
          <span className="big-number">$396</span>
        </div>
        </div>

      </div>
      <div className="container box-promo  d-sm-block d-md-none">
        <div className="row text-center">
          <div  className="col-12">
          <span style={{ fontSize: "45px" }}>
            UN SEGURO
            <br /> QUE TE BRINDA
            <br /> TRANQUILIDAD
          </span>
          </div>
          <div className="col-12 pt-5">
          <h2>POR MES DESDE</h2>
          <span className="display-1">$396</span>
        </div>
        </div>

      </div>
    </div>
  );
};

SeccionPromohogar.propTypes = {};

export default SeccionPromohogar;
