import React, { useState } from "react";
import "./CoberturaMovil.css";

const CoberturaMovil = (props) => {
  const [d, setD] = useState(props.data || null);
  const formatoMoneda = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formatoPesosArgentinos = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });
  return (
    <>
      <li
        className="list-group-item "
        style={{ color: "#000", padding: "5px" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Responsabilidad Civil:</strong>
          </div>
          <div>{d.ResponsabilidadCivil}</div>
        </div>
      </li>
      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Robo Hurto Total:</strong>
          </div>
          <div>{d.RoboHurtoTotal !== "NO" ? d.RoboHurtoTotal : "-"}</div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Robo Hurto Parcial:</strong>
          </div>
          <div>{d.RoboHurtoParcial !== "NO" ? d.RoboHurtoParcial : "-"}</div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Incendio Total:</strong>
          </div>
          <div>{d.IncendioTotal !== "NO" ? d.IncendioTotal : "-"}</div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Destruccion Total:</strong>
          </div>
          <div>{d.DestruccionTotal !== "NO" ? d.DestruccionTotal : "-"}</div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Cristales Laterales:</strong>
          </div>
          <div>
            {d.CristalesLaterales !== "NO" ? d.CristalesLaterales : "-"}
          </div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Daño Parcial amparo Robo Total:</strong>
          </div>
          <div>
            {d.DanoParcialalamparoRoboTotal !== "NO"
              ? d.DanoParcialalamparoRoboTotal
              : "-"}
          </div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Clausula Destruccion Total:</strong>
          </div>
          <div>
            {d.ClausulaDestruccionTotal !== "NO"
              ? d.ClausulaDestruccionTotal
              : "-"}
          </div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Ajuste Automatico Suma:</strong>
          </div>
          <div>
            {d.AjusteAutomaticoSuma !== "NO" ? d.AjusteAutomaticoSuma : "-"}
          </div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Incendio Parcial:</strong>
          </div>
          <div>{d.IncendioParcial !== "NO" ? d.IncendioParcial : "-"}</div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Granizo:</strong>
          </div>
          <div>{d.Granizo !== "NO" ? d.Granizo : "-"}</div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Luneta Parabrisa:</strong>
          </div>
          <div>{d.LunetaParabrisa !== "NO" ? d.LunetaParabrisa : "-"}</div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Daño Parcial Accidente:</strong>
          </div>
          <div>
            {d.DanoParcialAccidente !== "NO" ? d.DanoParcialAccidente : "-"}
          </div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Otros Daños Parciales:</strong>
          </div>
          <div>
            {d.OtrosDanosParciales !== "NO" ? d.OtrosDanosParciales : "-"}
          </div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Reposición 0Km:</strong>
          </div>
          <div>{d.Reposicion0Km !== "NO" ? d.Reposicion0Km : "-"}</div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Auto Sustituto</strong>
          </div>
          <div>{d.AutoSustituto !== "NO" ? d.AutoSustituto : "-"}</div>
        </div>
      </li>

      <li className="list-group-item" style={{ color: "#000", padding: "5px" }}>
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Remolque:</strong>
          </div>
          <div>{d.ServiRemolque !== "NO" ? d.ServiRemolque : "-"}</div>
        </div>
      </li>

      <li
        className="list-group-item"
        style={{ color: "#000", padding: "5px", background: "#e9e9e9" }}
      >
        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Seguro Muerte Conductor:</strong>
          </div>
          <div>{formatoPesosArgentinos.format(d.SeguroMuerteConductor)}</div>
        </div>
      </li>
    </>
  );
};

export default CoberturaMovil;
