import React from "react";

import Footer from "../../components/Footer/Footer";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";

function Baja() {
  return (
    <div>
      <NavBarSeg/>
      <div className="container" style={{paddingTop:'80px'}}>
       
        
      </div>
      <SeccionAtencion/>
      <Footer />
    </div>
  );
}

export default Baja;
