"use client";
import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
  );
  
  function obtenerDiasSemana(fecha) {
	var diasSemana = ['dom', 'lun', 'mar', 'mier', 'jue', 'vie', 'sab'];
	const resultado = [];
  
	// Clonar la fecha para no modificar la original
	var fechaActual = new Date(fecha);
  
	// Obtener el día de la semana de la fecha dada
	var diaSemana = fechaActual.getDay();
  
	// Retroceder al domingo de la semana actual
	fechaActual.setDate(fechaActual.getDate() - diaSemana);
  
	// Obtener los nombres de los días y las fechas de la semana
	for (var i = 0; i < 7; i++) {
	  var nombreDia = diasSemana[i];
	  var fechaDia = fechaActual.toISOString().split('T')[0];
  
	  resultado.push({"fecha": fechaDia,"cantidad": "0"});
  
	  // Avanzar al siguiente día
	  fechaActual.setDate(fechaActual.getDate() + 1);
	}
  
	return resultado;
  }
  function obtenerMesAbreviadoYDia(fecha) {
	const mesesAbreviados = [
	  'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
	];
  
	const fechaObjeto = new Date(fecha);
	const dia = fechaObjeto.getDate();
	const mesAbreviado = mesesAbreviados[fechaObjeto.getMonth()];
  
	// Formatea la fecha como "Ene 17"
	const fechaFormateada = `${mesAbreviado} ${dia < 10 ? '0' : ''}${dia}`;
  
	return fechaFormateada;
  }
  
  // Ejemplo de uso con la fecha 17/01/2023
  var fechaEjemplo = new Date();
  var diasSemana = obtenerDiasSemana(fechaEjemplo);

  const options =[]



const GraficoConsultasHogar = (props) => {
	const hogar = props.hogar
 

	  const countByDay = {};

hogar.forEach(item => {
  const fecha = obtenerMesAbreviadoYDia(item.create_at.split(" ")[0]); // Obtener la parte de la fecha sin la hora
  countByDay[fecha] = (countByDay[fecha] || 0) + 1; // Incrementar la cuenta para la fecha actual
});
	
const arrayDePares = Object.entries(countByDay);

// Ordenar el array de pares según la posición original
const arrayDeParesOrdenado = arrayDePares.sort((a, b) => {
  const indiceA = Object.keys(countByDay).indexOf(a[0]);
  const indiceB = Object.keys(countByDay).indexOf(b[0]);
  return indiceA - indiceB;
});

// Invertir el orden del array de pares
const arrayDeParesInvertido = arrayDeParesOrdenado.reverse();

// Construir el nuevo objeto con las claves invertidas
const nuevoObjeto = Object.fromEntries(arrayDeParesInvertido);
const nuevoObjeto2 = [0,0,0,0,0,0]

	  const data = {
		labels: "",
		datasets: [
		  {
			label: 'Consuta cotizacion hogar',
			data:  nuevoObjeto,
			tension: 0.5,
			fill: true,
			borderColor: 'rgb(23, 160, 213)',
			backgroundColor: '#15bdff1f',
			pointRadius: 5,
			pointBorderColor: 'rgb(23, 160, 213)',
			pointBackgroundColor: '#8bcfea63',
		  }

		],
	  };
	return (
		<Line options={options} data={data} />
	);
};



export default GraficoConsultasHogar;