import React, { useState } from "react";

const Cobertura = (props) => {

const [d,setD]= useState(props.data || null)
const formatoMoneda = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "ARS",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const formatoPesosArgentinos = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "ARS",
});
  return (
    <>
          <li className="list-group-item text-center" style={{color:'#000',padding:'5px'}}>{d.ResponsabilidadCivil}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}> {d.RoboHurtoTotal !== 'NO' ? d.RoboHurtoTotal : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}> {d.RoboHurtoParcial !== 'NO' ? d.RoboHurtoParcial : '-'}</li>          
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}> {d.IncendioTotal !== 'NO' ? d.IncendioTotal : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}> {d.DestruccionTotal !== 'NO' ? d.DestruccionTotal : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}>{d.CristalesLaterales !== 'NO' ? d.CristalesLaterales : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}>{d.DanoParcialalamparoRoboTotal !== 'NO' ? d.DanoParcialalamparoRoboTotal : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}>{d.ClausulaDestruccionTotal !== 'NO' ? d.ClausulaDestruccionTotal : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}>{d.AjusteAutomaticoSuma !== 'NO' ? d.AjusteAutomaticoSuma : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}>{d.IncendioParcial  !== 'NO' ? d.IncendioParcial : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}>{d.Granizo !== 'NO' ? d.Granizo : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}> {d.LunetaParabrisa !== 'NO' ? d.LunetaParabrisa : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}> {d.DanoParcialAccidente !== 'NO' ? d.DanoParcialAccidente : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}> {d.OtrosDanosParciales !== 'NO' ? d.OtrosDanosParciales : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}>{d.Reposicion0Km !== 'NO' ? d.Reposicion0Km : '-'}</li>          
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}>{d.AutoSustituto !== 'NO' ? d.AutoSustituto : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px'}}>{d.ServiRemolque !== 'NO' ? d.ServiRemolque : '-'}</li>
          <li className="list-group-item text-center" style={{padding:'5px', background:'#e9e9e9'}}>{formatoPesosArgentinos.format(d.SeguroMuerteConductor)}</li>
       </>
  );
};

export default Cobertura;
