import { NavLink } from "react-router-dom";
import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Navb.css";

function NavbFooterHome() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <ul style={{fontSize:'1.5rem'}}>
              <li><NavLink exact to={'/'}>Inicio</NavLink></li>
              <li><NavLink exact to={'/seguro-auto'}>Seguro Auto</NavLink></li>
              <li><NavLink exact to={'/seguro-hogar'}>Seguro Hogar</NavLink></li>
              <li><NavLink exact to={'/contactenos'}>Contacto</NavLink></li>
            </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="d-sm-block d-md-none">
      <div id="footer-bar" className="footer-bar footer-bar-detached">
        <NavLink exact to="/">
          <BsIcons.BsWindow className="font-17" />
          <span>Inicio</span>
        </NavLink>
        <NavLink exact to="/seguro-auto">
          <BsIcons.BsCarFront className="font-17" />
          <span>Auto</span>
        </NavLink>
        <NavLink exact to="/seguro-hogar">
          <BsIcons.BsHouseLock className="font-17" />
          <span>Hogar</span>
        </NavLink>
        <NavLink exact to="/contactenos">
          <BsIcons.BsTelephone className="font-17" />
          <span>Contacto</span>
        </NavLink>
      </div>
      </div>
    </>
  );
}

export default NavbFooterHome;
