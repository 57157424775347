
import "./home.css";

import Footer from "../../components/Footer/Footer";

import ButtonWhatsapp from "../../components/ButtonWhatsapp/ButtonWhatsapp"
import NavbFooterHome from "../../components/NavFooterHome"
import { url } from "../../service/EndPoint";
import SeccionBaja from "../../components/SeccionBaja/SeccionBaja";
import SeccionCanales from "../../components/SeccionCanales/SeccionCanales";
import SeccionEstamos from "../../components/SeccionEstamos/SeccionEstamos";
import SeccionNuestros from "../../components/SeccionNuestros/SeccionNuestros";
import SeccionSlider from "../../components/SeccionSlider/SeccionSlider";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";

function Home(props) {

  return (
    <>
     <NavBarSeg className="d-none d-sm-none d-md-block"/>
    <NavbFooterHome/>
    
     <div>
      <div className="d-none d-sm-none d-md-block">
      <SeccionSlider info={props.context.informacion}/>
      </div>
      <div className="d-sm-block d-md-none">
      <img
          src={
            url + "media/imagenes/" + props.context.home.background_home_mobile
          }
          alt="Ruta"
          className=" d-sm-block d-md-none"
          style={{ width: "100%" }}
        />
      </div>
       
        <br/>
        <SeccionNuestros/>
        <br/>
       
        <SeccionCanales info={props.context.informacion}/>
        <SeccionBaja/>
        <SeccionAtencion info={props.context.informacion}/>
        <ButtonWhatsapp info={props.context.informacion}/>
        <Footer/>
      </div>
    </>
  );
}
export default Home;
