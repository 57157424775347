import React, { useState, useEffect } from "react";
import { url } from "../../service/EndPoint";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import moment from "moment-timezone";
import "moment/locale/es";
import "./ListaChat.css";

const ListaChat = ({}) => {
	const history = useHistory();
  moment.tz.setDefault("America/Argentina/Buenos_Aires");
  const [lista, setLista] = useState([]);

  const getData = () => {
    axios
      .post(url + "mensajeria/list/consultas.php")
      .then((response) => {
        setLista(response.data.datos);
       
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  
  function irChat(e){
	let datos = {
		id_receptor: e.codigo,
		id_emisor: 1,
		nombre: e.nombre

	}
	history.push(
        "/lista-chat/chat/" +
          encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify(datos),
              "clave_secreta"
            ).toString()
          )
      );
  }
  const fetchData = async () => {
    try {
      const response = await axios.post(url + "mensajeria/list/consultas.php");
      setLista(response.data.datos);
    } catch (error) {
      // Manejar el error aquí
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango de 2xx
    //   console.error("Respuesta del servidor:", error.response.data);
      //  console.error("Código de estado HTTP:", error.response.status);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
     //   console.error("No se recibió respuesta del servidor");
      } else {
        // Ocurrió un error durante la configuración de la solicitud
      //  console.error("Error al configurar la solicitud:", error.message);
      }
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {

      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="container">
      <div className="row pt-3">
        <div className="col-1">
          <NavLink exact to={"/dashboard"}>
            <h1>
              <BsIcons.BsArrowLeftShort className="app-color display-1" />
            </h1>
          </NavLink>
        </div>
        <div className="col">
          <h1 className="titulo-1 pt-3">Lista de chat</h1>
        </div>
      </div>
      <div className="row">
        {lista &&
          lista.map((item, i) => (
            <div className="col-md-6 col-sm-12 mb-3" key={i} onClick={()=>irChat(item)}>
            
                <div className="card">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{item.nombre}</div>
                      <div>
                        {moment(item.create_at).format("HH:mm")}{" "}
                        {Number(item && item.estado) === 1 && (
                          <span className="badge-warning">Pendiente</span>
                        )}{" "}
                        {Number(item && item.estado) === 2 && (
                          <span className="badge-success">Cerrada</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListaChat;
