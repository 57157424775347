import "./UpdateImage.css";
import React, { useRef, useState } from "react";
import * as BsIcons from "react-icons/bs";
import { url } from "../../service/EndPoint";
import axios from "axios";
import "./styles.css";



function UpdateImage(props) {
  const imgProp = props.url;
  const inputRef = useRef("");
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = useState(
    imgProp ? url + "media/imagenes/"  + imgProp : null
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const datas = new FormData();
        datas.append("id", 1);
        datas.append("file", file);
        
        axios
          .post (url + "media/update/"+props.base+".php" , datas, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            let { data } = res;
            if (Number(data.success) === 1) {
            window.alert('Actualizada')
             
            }
          })
          .catch(function (res) {
            console.log(res);
          });
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  function handleSubmit(e) {

  }

  return (
    <div className="text-center ">
      <div className="text-center">
        {image ? (
          <>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              capture="camera"
              onChange={handleImageChange} 
              ref={inputRef}
              style={{ display: "none" }}
            />
            <img
              src={image}
              alt=""
              className="img-fluid"
             
              onClick={triggerFileSelectPopup}
            />
          </>
        ) : (
          <>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              capture="camera"
              onChange={handleImageChange} 
              ref={inputRef}
              style={{ display: "none" }}
            />
            <h1>
              <BsIcons.BsPlusCircleFill onClick={triggerFileSelectPopup} />
            </h1>
          </>
        )}
       
      </div>
    </div>
  );
}

export default UpdateImage;
