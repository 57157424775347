import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { url } from "../service/EndPoint";
import * as AiIcons from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Navb.css";

function NavbFooter(props) {
  const auth = props.auth;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [lista, setLista] = useState([]);
  const fetchData = async () => {
    try {
      await axios.post(url + `cotizaciones/reportes/reportes.php`)
      .then((res) => {
        let { data } = res;
  
        if (data.success && data.message) {
          if (data.success === 1) {
             setLista(data.chat)
          }
        }
      })
      
      
    } catch (error) {
      // Manejar el error aquí
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango de 2xx
    //   console.error("Respuesta del servidor:", error.response.data);
      //  console.error("Código de estado HTTP:", error.response.status);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
     //   console.error("No se recibió respuesta del servidor");
      } else {
        // Ocurrió un error durante la configuración de la solicitud
      //  console.error("Error al configurar la solicitud:", error.message);
      }
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="row" style={{fontSize:'1.5rem'}}>
             <NavLink exact to={'/dashboard'}><div className="botonLateral">Inicio</div></NavLink>
              <NavLink exact to={'/consultas/lista'}><div className="botonLateral">Consultas</div></NavLink>
              <NavLink exact to={'/lista-cotizaciones/auto'}><div className="botonLateral">Auto</div></NavLink>
              <NavLink exact to={'/lista-cotizaciones/hogar'}><div className="botonLateral">Hogar</div></NavLink>
             <NavLink exact to={'/lista-chat'}><div className="botonLateral">Chat</div></NavLink>
            </div>
          <div style={{display: 'flex',
                       justifyContent: 'center',
                       height:'50%',
                       alignItems: 'flex-end'
        }}>

     
          <button className="btn app-btn" onClick={auth.logoutUser}>
            Cerrar sesion
          </button>
      
          </div>

        </Offcanvas.Body>
      </Offcanvas>
      <div id="footer-bar" className="footer-bar footer-bar-detached">
        <NavLink exact to="/dashboard">
          <AiIcons.AiOutlineHome className="font-17" />
          <span>Inicio</span>
        </NavLink>
        <NavLink exact to="/consultas/lista">
          <BsIcons.BsEnvelopePaper className="font-17" />
          <span>Consultas</span>
        </NavLink>
        <NavLink exact to="/lista-cotizaciones/auto">
          <BsIcons.BsSpeedometer className="font-17" />
          <span>Cotizaciónes</span>
        </NavLink>
        <NavLink exact to="/lista-chat">
          <BsIcons.BsChatLeft className="font-17" />
                         <span
                    className="position-absolute top-0 start-50 translate-middle badge rounded-pill"
                    style={{
                      backgroundColor:
                        lista.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {lista && lista.length}
                  </span>
                  <span>Chat</span>
        </NavLink>
        <div onClick={handleShow}>
          <BsIcons.BsGrid className="font-17" />
          <span>Menú</span>
        </div>
      </div>
    </>
  );
}

export default NavbFooter;
