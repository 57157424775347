import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../auth/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha";
import "./Login.css";

function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const { loginUser, isLoggedIn } = useContext(AuthContext);

  const initialState = {
    userInfo: {
      email: "",
      password: "",
    },
    errorMsg: "",
    successMsg: "",
  };
  const [state, setState] = useState(initialState);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onChangeValue = (e) => {
    setState({
      ...state,
      userInfo: {
        ...state.userInfo,
        [e.target.name]: e.target.value,
      },
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const data = await loginUser(state.userInfo);

    if (data.token) {
      setState({
        ...initialState,
      });

      localStorage.setItem("loginToken", data.token);
      await isLoggedIn();
    } else {
      //console.log(data);
    }
    e.target.reset();
  };
  const [activo, setActivo] = useState(true);
  function handleRecaptchaChange(value) {
    if (value) {
      setActivo(false);
    }
  }
  return (
    <div className="login App-header-up" style={{ padding: "90px" }}>
      <div className="container pt-3">
        <h1>Acceso productor</h1>
        <div className="col-md-6 col-12  pt-5 mx-auto">
          <div className="container mb-5 text-start">
            <form onSubmit={submitForm}>
              <small>Correo electrónico / Teléfono</small>
              <div className="input-group">
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  onChange={onChangeValue}
                  required
                />
              </div>
              <div
                className="mb-3"
                style={{ borderTop: "solid 1px black" }}
              ></div>
              <small>Contraseña</small>
              <div className="input-group">
                <input
                  name="password"
                  type={passwordType}
                  className="form-control"
                  onChange={onChangeValue}
                  required
                />
                <span
                  className="input-group-text app-click"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <small>Mostrar</small>
                  ) : (
                    <small>Ocultar</small>
                  )}
                </span>
              </div>
              <div
                className="mb-3"
                style={{ borderTop: "solid 1px black" }}
              ></div>
              <br />
              <ReCAPTCHA
                style={{ display: "flex", justifyContent: "center" }}
                sitekey="6LeURH0oAAAAAEcZSiDZVhyjiSN7mFwhmRDFLGpb"
                onChange={handleRecaptchaChange}
              />
              <br />
              <div className="d-grid  mb-4">
                <button
                  type="submit"
                  className="btn app-button text-white shadow"
                 disabled={activo}
                >
                  Iniciar sesión
                </button>
              </div>
              <div className="text-space mb-5">
                <NavLink exact to="/restore">
                  <small>Olvidó la contraseña ?</small>
                </NavLink>
                <NavLink exact to="/">
                  <small>Volver</small>
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
