import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../service/EndPoint";
import "./Media.css";
import UpdateImage from "../../components/UpdateImage/UpdateImage";

const Media = ({}) => {
  const [home, setHome] = useState(false);
  const [hogar, setHogar] = useState("");
  const [auto, setAuto] = useState("");

  function getData() {
    axios
      .post(url + "media/get/media.php")
      .then((getData) => {
        // Manejar la respuesta exitosa aquí
        setHome(getData.data.home[0]);
        setHogar(getData.data.hogar[0]);
        setAuto(getData.data.auto[0]);
        console.log(getData.data.home[0]);
      })
      .catch((error) => {
        // Manejar el error aquí
        console.log("Error al obtener datos:", error);
      });
  }

  useEffect(() => {
    getData();

  }, []);

  return (
    <div className="media">
      <div className="container">
        <div className="text-start">
          <NavLink exact to={"/dashboard"}>
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort className="app-color" />
            </h1>
          </NavLink>
        </div>
        <div
          className="card shadow mb-3"
          style={{ background: "#292740", border: "0px" }}
        >
          <div className="card-body">
            <div className="text-center text-white">Media</div>
          </div>
        </div>
        <div className="text-center pt-5 " style={{ color: "#292740" }}></div>
        Background Home
        <div className="row mb-3 pt-5">
          <div className="col-6">
            {home && <UpdateImage url={home && home.background_home}  base={'update'}/>}
            <small className="text-muted">1500 x 580</small>
          </div>
          <div className="col-6">
            {home && <UpdateImage url={home && home.background_home_mobile}  base={'update1'} />}
            <small className="text-muted">320 x 192</small>
          </div>
        </div>
        Background Auto
        <div className="row mb-3 pt-5">
          <div className="col-6">
            {auto && <UpdateImage url={auto && auto.background_auto}  base={'update2'}/>}

            <small className="text-muted">1500 x 580</small>
          </div>
          <div className="col-6">
            {auto && <UpdateImage url={auto && auto.background_auto_mobile}  base={'update3'}/>}

            <small className="text-muted">320 x 192</small>
          </div>
        </div>
        Background hogar
        <div className="row mb-3 pt-5">
          <div className="col-6">
            {hogar && <UpdateImage url={hogar && hogar.background_hogar}  base={'update4'}/>}

            <small className="text-muted">1500 x 580</small>
          </div>
          <div className="col-6">
            {hogar && (
              <UpdateImage url={hogar && hogar.background_hogar_mobile}  base={'update5'}/>
            )}

            <small className="text-muted">320 x 192</small>
          </div>
        </div>
      </div>
    </div>
  );
};

Media.propTypes = {};

export default Media;
