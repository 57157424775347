import { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../../components/Footer/Footer";
import { TiHome } from "react-icons/ti";
import icono from "../../assets/img/tres-pasos-cotiza-icon.svg";
import icono1 from "../../assets/img/tres-pasos-plan-icon.svg";
import icono2 from "../../assets/img/tres-pasos-contrata-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import { url } from "../../service/EndPoint";
import "./SeguroHogar.css";
import NavbFooterHome from "../../components/NavFooterHome";
import { BsShield } from "react-icons/bs";
import { BsGeoAlt } from "react-icons/bs";
import ButtonWhatsapp from "../../components/ButtonWhatsapp/ButtonWhatsapp";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import SeccionAdicionales from "../../components/SeccionAdicionales/SeccionAdicionales";
import SeccionPromohogar from "../../components/SeccionPromohogar/SeccionPromohogar";
import { FaHandHoldingHand } from "react-icons/fa6";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";
function SeguroHogar(props) {
  const [cotizacion, setCotizacion] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    codigoPostal: "",
    localidad: "",
    tipo: "",
    productor: "",
  });

  const [token, setToken] = useState("");
  const [codPostal, setCodPostal] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [disabled1, setDisabled1] = useState(true);

  async function fetchToken() {
    try {
      const response = await axios.post(
        "https://autos-test.apiexperta.com.ar/login",
        {
          user: "grupoabsa",
          password: "$3XP3rt4*BR0k3R",
        }
      );

      const { jwt } = response.data;
      setToken(jwt);
    } catch (error) {
      console.error("Error al obtener el token:", error);
    }
  }

  useEffect(() => {
    fetchToken();

    const intervalId = setInterval(() => {
      fetchToken();
    }, 900000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onChangeValue = (e) => {
    setCotizacion({
      ...cotizacion,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    async function fetchLocalidades() {
      if (codPostal.length === 4) {
        try {
          const response = await axios.get(
            "https://autos-test.apiexperta.com.ar/commons/localidades?codPostal=" +
              codPostal,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setLocalidades(response.data.localidades);
          setDisabled1(false);
        } catch (error) {
          console.error("Error al obtener las localidades:", error);
        }
      } else {
        setDisabled1(true);
      }
    }

    fetchLocalidades();
  }, [codPostal, token]);

  function handleRecaptchaChange(value) {
    if (value) {
      setActivo(false);
    }
  }
  const [activo, setActivo] = useState(true);
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function validarCorreoElectronico(correo) {
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  }

  function enviar() {
    setActivar(false);
    if (!validarCorreoElectronico(cotizacion.email)) {
      setActivar(true);
      alert(
        "Por favor, debes completar los campos y ingresar un correo valido"
      );
    } else {
      axios
        .post(url + `cotizaciones/hogar/add.php`, cotizacion)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }

  const [activeKey, setActiveKey] = useState("");
  const [viewPlanes, setViewPlanes] = useState(true);
  const [viewPlanes1, setViewPlanes1] = useState(true);
  const [viewPlanes2, setViewPlanes2] = useState(true);

  const handleAccordionClick = (accordionKey) => {
    if (accordionKey === activeKey) {
      setViewPlanes(true);
      setViewPlanes1(true);
      setViewPlanes2(true);
    } else {
      if (Number(accordionKey) === 0) {
        setViewPlanes1(false);
        setViewPlanes2(false);
      }
      if (Number(accordionKey) === 1) {
        setViewPlanes(false);
        setViewPlanes2(false);
      }
      if (Number(accordionKey) === 2) {
        setViewPlanes1(false);
        setViewPlanes(false);
      }
    }
    setActiveKey(accordionKey === activeKey ? "" : accordionKey);
  };

  return (
    <>
     <NavBarSeg className="d-none d-sm-none d-md-block"/>
      <div className="segurohogar">
    
        <div className="fondo-hogar ">
          <div className="fondo-hogar-logo">
            <TiHome className="icono-hogar" />
            <h1>
              SEGURO <br />
              HOGAR
            </h1>
          </div>
        </div>

        <div className="container">
        <NavbFooterHome />
        <ButtonWhatsapp info={props.context.informacion} />
          <div className="d-sm-block d-md-none text-center h1 pt-5 mb-5" style={{fontStyle:'italic'}}>
            TU HOGAR ES TU LUGAR,
            <br />
            PROTEGELO.
          </div>
          <div className="d-none d-sm-none d-md-block titulo-hogar pt-4" style={{fontStyle:'italic'}}>
            TU HOGAR ES TU LUGAR,
            <br />
            PROTEGELO.
          </div>
        </div>

        <SeccionPromohogar />
        <div className="container pt-5">
          <div className="row ">
            <div className="col-md-6 col-sm-12">
              <div className="columna-planes">
                <div className="text-start">
                  <span className="info-text">PLANES</span>
                  <br />
                  <br />
                  <Accordion activeKey={activeKey}>
                    <Accordion.Item
                      eventKey="0"
                      onClick={() => handleAccordionClick("0")}
                      className={viewPlanes ? "fade-in" : "fade-out"}
                    >
                      <Accordion.Header>
                        <BsGeoAlt style={{ fontSize: "30px" }} />{" "}
                        <span style={{ paddingLeft: "10px", fontSize: "30px" }}>
                          CERCANÍA
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Un plan que te permite asegurar tu vivienda a un precio
                        preferencial.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br />
                    <Accordion.Item
                      eventKey="1"
                      onClick={() => handleAccordionClick("1")}
                      className={viewPlanes1 ? "fade-in" : "fade-out"}
                    >
                      <Accordion.Header style={{ fontSize: "30px" }}>
                      <FaHandHoldingHand />
                        <span style={{ paddingLeft: "10px", fontSize: "30px" }}>
                          PROTECCIÓN
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Una alternativa que te dá respaldo y tranquilidad para
                        que puedas disfrutar de tu hogar.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br />
                    <Accordion.Item
                      eventKey="2"
                      onClick={() => handleAccordionClick("2")}
                      className={viewPlanes2 ? "fade-in" : "fade-out"}
                    >
                      <Accordion.Header>
                        <BsShield style={{ fontSize: "30px" }} />{" "}
                        <span style={{ paddingLeft: "10px", fontSize: "30px" }}>
                          SEGURIDAD
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Un plan que además de asegurar los bienes de tu casa,
                        incluye una red de asistencias adicionales para resolver
                        cualquier inconveniente que te pueda surgir.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="col-12">
                <br />
                <div className="row-app" id="section-3">
                  <div className="pt-5 text-start mb-5">
                    <h1>TU PÓLIZA 100% ONLINE</h1>
                  </div>
                </div>
                <div className="box-container">
                  <div className="box-poliza">
                    <h4>Cotizá</h4>
                    <div>
                      <img src={icono} width="67" alt="seguro" />
                    </div>
                  </div>

                  <div className="box-poliza-1">
                    <h4>Elegí tu plan</h4>
                    <div>
                      <img src={icono1} width="67" alt="seguro" />
                    </div>
                  </div>

                  <div className="box-poliza-2">
                    <h4>Contratá</h4>
                    <div>
                      <img src={icono2} width="67" alt="seguro" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="container-formulario">
                <div className="text-start">
                  <h2 className="color-app">COTIZÁ AHORA</h2>
                </div>

                {exito ? (
                  <>
                    <div className="row">
                      <form>
                        <div className="row">
                          <div className="text-start pt-2 color-app">
                            <h4>Datos de contacto</h4>
                          </div>
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="nombre"
                                  placeholder="NOMBRE"
                                  onChange={onChangeValue}
                                  required
                                />
                                <label htmlFor="Nombre">
                                  <small>NOMBRE</small>
                                </label>
                                <div className="invalid-feedback">
                                  Por favor completa este campo
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="apellido"
                                  placeholder="APELLIDO"
                                  onChange={onChangeValue}
                                  required
                                />
                                <label htmlFor="Apellido">
                                  <small>APELLIDO</small>
                                </label>
                                <div className="invalid-feedback">
                                  Por favor completa este campo
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="dni"
                                  placeholder="DNI"
                                  pattern="[0-9]+"
                                  minLength={6}
                                  maxLength={10}
                                  onChange={onChangeValue}
                                  required
                                />
                                <label htmlFor="DNI">
                                  <small>DNI</small>
                                </label>
                                <div className="invalid-feedback">
                                  Por favor completa este campo
                                </div>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="telefono"
                                  placeholder="01143214321"
                                  pattern="[0-9]+"
                                  minLength={6}
                                  maxLength={15}
                                  onChange={onChangeValue}
                                  required
                                />
                                <label htmlFor="CELULAR">
                                  <small>CELULAR</small>
                                </label>
                                <div className="invalid-feedback">
                                  Por favor completa este campo
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="row g-2">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                placeholder="E.MAIL"
                                onChange={(e) => onChangeValue(e)}
                                required
                              />
                              <label htmlFor="mail">
                                <small>E.MAIL</small>
                              </label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row g-2 mb-3">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="codigo"
                                name="codigoPostal"
                                placeholder="1234"
                                minLength={4}
                                maxLength={4}
                                onChange={(e) => {
                                  onChangeValue(e);
                                  setCodPostal(e.target.value);
                                }}
                              />
                              <label htmlFor="codigo">
                                <small>CÓD. POSTAL</small>
                              </label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <select
                                className="form-select"
                                name="localidad"
                                disabled={disabled1}
                                onChange={onChangeValue}
                                required
                              >
                                <option></option>
                                {localidades &&
                                  localidades.map((data, index) => (
                                    <option key={index} value={data.localidad}>
                                      {data.localidad}
                                    </option>
                                  ))}
                              </select>
                              <label htmlFor="LOCALIDAD">
                                <small>LOCALIDAD</small>
                              </label>
                              <div className="invalid-feedback">
                                Por favor completa este campo
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-2">
                          <div className="col-md">
                            <div className="container-check">
                              <div className="text-start mb-2">
                                <small style={{ opacity: "0.65" }}>
                                  TIPO DE CASA
                                </small>
                              </div>

                              <div className="check-option">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    name="tipo"
                                    type="radio"
                                    value="casa"
                                    onChange={onChangeValue}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="casa"
                                  >
                                    Casa
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    name="tipo"
                                    type="radio"
                                    value="country"
                                    onChange={onChangeValue}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="country"
                                  >
                                    Country
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    name="tipo"
                                    type="radio"
                                    value="depto"
                                    onChange={onChangeValue}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="depto"
                                  >
                                    Depto PB/1er piso
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    name="tipo"
                                    type="radio"
                                    value="depto2"
                                    onChange={onChangeValue}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="depto2"
                                  >
                                    Departamento (desde 2do piso)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <br />
                    <div>
                      <ReCAPTCHA
                        style={{ display: "flex", justifyContent: "center" }}
                        sitekey="6LeURH0oAAAAAEcZSiDZVhyjiSN7mFwhmRDFLGpb"
                        onChange={handleRecaptchaChange}
                      />
                      <button
                        className="btn-contact-auto"
                        disabled={activo}
                        onClick={() => activar && enviar()}
                      >
                        QUIERO SER CONTACTADO
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="h3 text-center pt-5">
                    Enviamos tu cotización !
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <SeccionAdicionales />
        <SeccionAtencion info={props.context.informacion} />
        <Footer />
      </div>
    </>
  );
}

export default SeguroHogar;
