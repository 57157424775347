import React from "react";
import Footer from "../../components/Footer/Footer";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";

function Donde(props) {

  const longitud = props.context.informacion.longitud ? props.context.informacion.longitud : '-68.85093910820875';
  const latitud = props.context.informacion.latitud ? props.context.informacion.latitud : '-32.89067667592222';
  

  const MapComponent = () => {
    return (
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6699.660029286504!2d${
          longitud
        }!3d${
          latitud
        }!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sar!4v1689773410011!5m2!1ses!2sar`}
        style={{ borderRadius: "10px", width: "100%" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    );
  };
  return (
    <div>
       <NavBarSeg className="d-none d-sm-none d-md-block"/>
      <div className="container " style={{paddingTop:'80px'}}>
      
        <div className="container pt-5"><br/>
        <div className="text-center">
        <span className="h1 text-app">¿Dónde estamos?</span>
        </div>
          <div className="text-start">
            
            <div className="text-start pt-5 mb-3">
            <span className="text-app"><strong>Podés encontrarnos en:</strong></span>
              <br />
              <span>{props.context && props.context.informacion.direccion}</span>
              <br />
              <span>Horarios: {props.context && props.context.informacion.horario}</span>
              <br />

            </div>
          </div>
          <br />
          <div><MapComponent/></div><br/>
        </div>
        
      </div>
      <SeccionAtencion />
      <Footer />
    </div>
  );
}

export default Donde;
